pn-accordion {
  display: block;
  position: relative;
  background-color: $white;

  > .pn-accordion-bg {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform-origin: top left;
    pointer-events: none;
    @include pn-transition('transform');

    [data-flipping] > & {
      transition: none;
      transform: scaleY(calc(1 / var(--dh)));
    }
  }
}
