import { Host, Component, Prop, h, Element, State, forceUpdate } from '@stencil/core';
import { open_in_new } from 'pn-design-assets/pn-assets/icons.js';
import { ripple } from '@/globals/helpers';

/**
 * @slot illustration - Set a custom image/svg/illustration.
 */
@Component({
  tag: 'pn-tile',
  styleUrl: 'pn-tile.scss',
})
export class PnTile {
  mo: MutationObserver;

  @Element() hostElement: HTMLElement;

  /** This is to keep track of whether the tile has a description or just a title */
  @State() simple: boolean = false;
  @State() emInPx: number = 16;
  @State() longpress: boolean = false;
  @State() timer: NodeJS.Timeout;

  /** Headline of the card and label of the link */
  @Prop() label: string;
  /** The SVG content of an illustration. */
  @Prop({ mutable: true }) illustration?: string;
  /** The link of the tile */
  @Prop() url: string;
  /** Force horizontal tile */
  @Prop() horizontal?: boolean = false;
  /** The target attribute of the link */
  @Prop() target?: string;
  /** The rel attribute of the link */
  @Prop() rel?: string = 'noopener';

  @State() checkCardSize = new ResizeObserver(cards => {
    const getCardWidth = (width: number): string => {
      // CardWidth is the em equivalent to 10% of the width of the card
      const cardWidth = (width * 0.1) / this.emInPx;

      return `${cardWidth}em`;
    };

    cards.forEach(card => {
      if (this.horizontal) return
      const width = card.contentRect.width;

      card.target.classList.toggle('vertical', width < this.remToPx(31.99));

      requestAnimationFrame(() => {
        (card.target as HTMLElement).style.setProperty('--w', getCardWidth(width));
      });
    });
  });

  setIllustration() {
    const illustrationElement = this.hostElement.querySelector('.tile-illustration');

    if (!this.illustration) {
      illustrationElement.remove();
      return;
    }

    const illustration = this.illustration;

    illustrationElement.outerHTML = illustration;
  }

  componentWillLoad() {
    const slottedIllustration = this.hostElement.querySelector('[slot="illustration"]');
    this.illustration = slottedIllustration ? null : this.illustration;
  }

  componentDidLoad() {
    if (this.mo) this.mo.disconnect();
    this.mo = new MutationObserver(() => forceUpdate(this.hostElement));
    this.mo.observe(this.hostElement, { childList: true });

    this.setIllustration();

    const cardEl = this.hostElement.querySelector<HTMLDivElement>('.pn-tile');
    const linkEl = this.hostElement.querySelector<HTMLLinkElement>('.pn-tile-link');

    this.checkCardSize.observe(cardEl);
    this.clickHandler([linkEl, cardEl], (e: MouseEvent) => ripple(e, cardEl));
    this.simple = !this.horizontal && !this.hostElement.querySelector('.tile-slot').textContent.trim();
    this.emInPx = parseFloat(window.getComputedStyle(this.hostElement).fontSize);
  }

  remToPx(rem: number): number {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  /*---------------------------------------UTILS-------------------------------------------*/

  clickHandler(elements: Array<HTMLLinkElement | HTMLDivElement>, callback: Function): void {
    const events = ['mouseup', 'mousedown'];
    const excludedElements = ['pn-button', 'button', 'a', 'input', 'pn-checkbox', 'pn-radio-button'];

    /**
     * @description Stop the tile-link from being pressed if the user clicks a link or a button that exists on the tile
     * This is to allow the consumer to add external actions to the tile
     */
    const isExcluded = (event: MouseEvent): boolean =>
      excludedElements.some(element => (event.target as HTMLElement).closest(element));

    /**
     * @description If this is a mousdown event, set state longpress to true after 200 milliseconds.
     */
    const longPressTrigger = (event: MouseEvent) => {
      if (event.type === 'mousedown') {
        this.longpress = false;
        this.timer = setTimeout(() => (this.longpress = true), 200);
      }
    };

    /**
     * @description Loop through all elements and attach out event listeners. See `events` array.
     */
    const loopAllElements = (element: HTMLElement, eventName: string): void => {
      element.addEventListener(eventName, (event: MouseEvent) => {
        if (isExcluded(event)) return;

        longPressTrigger(event);

        if (event.type === 'mouseup') {
          clearTimeout(this.timer);
          // Stop clicks in case of:
          // * Long press
          // * Right mouse button is used
          if (this.longpress || event.button === 2) return;
          const link = (event.target as HTMLElement).closest('pn-tile').querySelector('a');

          event.preventDefault();
          callback(event);

          // Don't refocus if already in focus
          if (!link.matches(':focus')) link.focus();

          // If the tile or link was clicked when the ctrl key, cmd key or middle mouse button
          // was pressed, open in a new window
          if (event.ctrlKey || event.metaKey || event.button === 1) {
            window.open(link.getAttribute('href'), '_blank');
            return;
          }

          link.click();
        }
      });
    };

    elements.forEach(element => {
      if (element.tagName === 'A') {
        element.addEventListener('click', event => callback(event));
        return;
      }
      events.forEach(eventName => loopAllElements(element, eventName));
    });
  }
  /*---------------------------------------/UTILS-------------------------------------------*/

  getClassNames() {
    let classNames = 'pn-tile ';
    if (this.simple) classNames += 'simple ';
    if (this.horizontal) classNames += 'horizontal ';

    return classNames;
  }

  getTextContClassNames() {
    let classNames = 'text-cont ';
    if (this.horizontal) classNames += 'horizontal ';

    return classNames;
  }

  render() {
    const linkAttributes = {
      href: this.url,
      target: this.target,
      rel: !this.rel && this.target === '_blank' ? 'noopener' : this.rel,
    };
    return (
      <Host>
        <a class="pn-tile-link" {...linkAttributes}>
          {this.label}
        </a>

        <div class={this.getClassNames()}>
          <div class="tile-circle"></div>

          <div class="tile-illustration"></div>

          {!this.illustration && (
            <div class="tile-illustration-slot">
              <slot name="illustration" />
            </div>
          )}

          <div class={this.getTextContClassNames()}>
            <h3>
              {this.label}
              {this.target === '_blank' ? <pn-icon icon={open_in_new} small={true} color="blue700"></pn-icon> : null}
            </h3>
            <div class="tile-slot">
              <slot />
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
