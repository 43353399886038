pn-search-field {
  display: inline-flex;
  gap: 0.5em;
}

pn-search-field input {
  @include pn-input-field;

  min-height: 3em;
  height: 100%;
  width: 100%;
  border-radius: 3em;

  &::-webkit-search-cancel-button {
    display: none;
  }
}

pn-search-field > .input-container {
  width: 100%;
  position: relative;

  .button-container {
    position: absolute;
    top: 0;
    right: 0;
    background: transparent;
    width: 3em;
    height: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    position: absolute;
    left: 50%;
    top: 50%;
    background: none;
    background-color: transparent;
    border: $onepixel solid transparent;

    padding: 0.25em;
    margin: 0;
    font-size: 0.875em;
    border-radius: 50%;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    @include pn-transition('transform, background-color, border, box-shadow, opacity');
    transition-delay: $timing, 0s, 0s, 0s, 0s;
    transform: translate(100%, -50%);

    &.clear,
    &.search {
      cursor: pointer;
    }

    &:focus {
      box-shadow: $focus-ring-default;
    }

    &:hover,
    &:focus {
      background: $input-button-background-hover;
    }

    &:active {
      background: $input-button-background-box-active;

      pn-icon .pn-icon-svg,
      pn-icon .pn-icon-svg path {
        fill: $white;
      }
    }
  }
}

pn-search-field.searching {
  button.search {
    transform: translate(150%, -50%);
  }
  button.clear {
    transform: translate(-50%, -50%);
  }
}

/*---------------------------------------Button Variants-------------------------------------------*/
pn-search-field.button-none,
pn-search-field.button-icon-inline {
  input {
    padding-right: 2em;
  }

  pn-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transition: transform $easing $timing;
    transform: translate(100%, -50%);
  }

  button.search {
    transform: translate(-50%, -50%);
  }

  &.loading {
    button {
      transform: translate(100%, -50%);
    }

    pn-spinner {
      transform: translate(-50%, -50%);
    }
  }
}

pn-search-field.button-none.searching button.search {
  transform: translate(100%, -50%);
}

pn-search-field.button-icon > pn-button {
  &[data-loading='true'] {
    .pn-button-bg {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
/*---------------------------------------/Button Variants-------------------------------------------*/
