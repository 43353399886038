$duration: 0.3s;
$delay: 0.15s;

pn-tooltip {
  display: inline-block;
  font-size: 1em;
}

pn-tooltip .pn-tooltip {
  background: none;
  background-color: transparent;
  outline: none;
  border: none;
  position: relative;
  padding: 0;
  border-radius: 50%;
  transition:
    box-shadow $duration $easing,
    background-color $duration $easing;
  cursor: pointer;
  font-size: inherit;

  &:focus {
    box-shadow: $focus-ring-default;
  }

  &:focus,
  &:hover {
    background-color: $blue50;
  }

  &[data-light] {
    pn-icon .pn-icon-svg path {
      fill: $white;
    }
    &:focus,
    &:hover {
      background-color: rgba($white, 0.2);
    }
  }

  &[data-warning] {
    pn-icon .pn-icon-svg path {
      fill: $warning;
    }

    &:focus {
      box-shadow: $focus-ring-error;
    }
    &:focus,
    &:hover {
      background-color: $coral50;
    }
  }

  &-content {
    position: absolute;
    bottom: calc(100% + 0.75em);
    left: 50%;
    background: $white;
    padding: 1em;
    border-radius: 0.8rem;
    box-shadow:
      0 1.2px 3.6px rgba(0, 0, 0, 0.1),
      0 6.4px 14.4px rgba(0, 0, 0, 0.13);
    z-index: 1;
    max-width: min(95vw, 40em);
    transition: transform $duration $easing;
    transition-delay: $delay;
    transform: scale(0) translateX(-50%);
    transform-origin: bottom left;
    width: max-content;
    color: $gray900;
  }

  > pn-icon {
    position: relative;
    pointer-events: none;

    &:before {
      content: '';
      width: 0;
      height: 0;
      border-left: 0.75em solid transparent;
      border-right: 0.75em solid transparent;
      border-top: 0.75em solid $white;
      filter: drop-shadow(0 0.3rem 0.1rem rgba(0, 0, 0, 0.075));
      bottom: 102%;
      left: 50%;
      position: absolute;
      z-index: 2;
      transition: transform $duration linear;
      transform: scale(0) translateX(-50%);
      transition-delay: 0;
      transform-origin: top left;
      pointer-events: none;
    }
  }

  &[data-open] {
    .pn-tooltip-content {
      transition-delay: 0;
    }

    pn-icon:before {
      transform: scale(1) translateX(-50%);
      transition-delay: $delay;
      pointer-events: auto;
    }
  }

  &[data-downwards] {
    .pn-tooltip-content {
      bottom: unset;
      top: calc(100% + 0.75em);
      transform-origin: top left;
    }

    pn-icon:before {
      top: 102%;
      transform-origin: bottom left;
      filter: drop-shadow(0 -0.3rem 0.1rem rgba(0, 0, 0, 0.02));
      border-top: none;
      border-bottom: 0.75em solid $white;
    }
  }
}
