import { Component, h, Host, Element, Event, EventEmitter, Prop } from '@stencil/core';
import { angle_down } from 'pn-design-assets/pn-assets/icons.js';
import { uuidv4, ripple } from '@/index';

/**
 * Use this row component inside a `pn-accordion` to create an accordion group.
 *
 * **Note**: you can use the `pn-accordion-row` without the parent accordion.
 * However, the animation when opening/closing the row will not be applied.
 *
 * @slot label - Include your own HTML as the label.
 * ```
 * <h3 slot="label">Custom title</h3>
 * ```
 */
@Component({
  tag: 'pn-accordion-row',
  styleUrl: 'pn-accordion-row.scss',
})
export class PnAccordionRow {
  id: string = `pn-accordion-row-${uuidv4()}`;

  @Element() hostElement: HTMLElement;

  /** The label/title of the accordion row, you can also pass a slot with the name "label" if you want to put custom content in there. */
  @Prop({ mutable: true }) label: string;
  /** Set the row as open. */
  @Prop({ mutable: true }) state?: boolean = false;
  /** Optional unique button id. */
  @Prop() buttonid?: string = `${this.id}-button`;
  /** Optional unique content id. */
  @Prop() contentid?: string = `${this.id}-content`;

  /** Dispatched every time the row is toggled. Includes the row element and the boolean `state` prop. */
  @Event() togglerow: EventEmitter<{ element: HTMLElement; state: Boolean }>;

  componentWillLoad() {
    const slottedLabel = !!this.hostElement.querySelector('[slot="label"]');
    this.label = slottedLabel ? null : this.label;
  }

  clickHandler(e: MouseEvent) {
    this.state = !this.state;

    this.togglerow.emit({ element: this.hostElement, state: this.state });

    const { x, width, y, top } = this.hostElement.getBoundingClientRect();
    const clientCor = e.clientX === 0 && e.clientY === 0 ? { clientX: x + width - 24, clientY: y - top } : e;

    ripple(clientCor, this.hostElement, '.pn-accordion-row-title');
  }

  render() {
    return (
      <Host>
        <div class="pn-accordion-row">
          <button
            id={this.buttonid}
            class="pn-accordion-row-title"
            aria-controls={this.contentid}
            aria-expanded={this.state.toString()}
            onClick={e => this.clickHandler(e)}
          >
            {this.label ? this.label : ''}
            <slot name="label" />

            <pn-icon class="pn-accordion-row-icon" icon={angle_down} />
          </button>

          <div id={this.contentid} class="pn-accordion-row-content" role="region" aria-labelledby={this.buttonid}>
            <slot />
          </div>
        </div>
      </Host>
    );
  }
}
