$border: 0.0625em;
$radius: 0.5em;

$timing: 0.3s;

pn-select {
  display: inline-block;
  min-width: 5em;
  &[checkbox] {
    min-width: 12em;
  }
}

pn-select .pn-select {
  &-label {
    @include pn-input-label;
  }

  @include pn-sr-only-class();

  &-input {
    position: relative;
  }

  &-content {
    @include pn-input-field;

    &[aria-disabled] {
      @include pn-disabled;
    }

    &[aria-readonly] {
      @include pn-readonly;
    }

    cursor: pointer;
    transition-delay: 0.1s, 0.1s, 0s;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5em;

    > span {
      margin-right: auto;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > pn-icon:last-of-type > svg > path {
      transition: transform $timing $easing 0.15s;
      transform-origin: center;
      transform: rotate(0deg);
    }

    &[aria-expanded='true'] {
      border-color: $blue700;
      border-radius: $radius;
      > pn-icon:last-of-type > svg > path {
        transform: rotate(180deg);
        transition-delay: 0s;
      }
    }

    &[data-placeholder] {
      @include pn-placeholder;
    }

    &:hover {
      color: $input-color;
      background-color: $input-background-hover;
      transition-delay: 0s;
    }

    &:focus {
      transition-delay: 0s;
    }
  }

  &-options {
    position: absolute;
    z-index: 10;
    top: calc(100% + 0.5em);
    left: 0;
    right: 0;
    width: 100%;
    opacity: 0;
    background-color: $white;
    box-shadow:
      0em 0.075em 0.225em 0em rgba(0, 0, 0, 0.1),
      0em 0.4em 0.9em 0em rgba(0, 0, 0, 0.13);

    border-radius: $radius;

    width: var(--width);
    max-height: var(--max-height);
    overflow-y: auto;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;

    visibility: hidden;

    transform-origin: top left;

    transform: scaleY(0) translateZ(0);

    &[data-moving] {
      transition-property: transform, opacity, visibility;
      transition-duration: $timing;
      transition-timing-function: $easing;
    }

    &[data-open] {
      opacity: 1;
      transform: scaleY(1) translateZ(0);
      visibility: visible;
    }

    &[data-upwards] {
      top: unset;
      bottom: calc(100% + 0.5em);
      transform-origin: bottom left;
      box-shadow: 0 -0.0625em 0.125em rgba(0, 0, 0, 0.2);
    }

    @include pn-scrollbar();

    > pn-input {
      padding: 0.5em;
    }
  }

  &-no-results {
    margin: 0;
    padding: 0.75em;
  }

  &-description {
    @include pn-input-helpertext;
    &[role='alert'] {
      color: $helpertext-color-error;
    }
  }

  &[data-error] {
    > .pn-select-label {
      color: $label-color-error;
    }

    .pn-select-content {
      @include pn-input-field-error;
      &:hover {
        background-color: $input-background-error-hover;
      }
    }
  }

  &[data-disabled] {
    > .pn-select-label,
    .pn-select-content {
      pointer-events: none;
      > pn-icon > .pn-icon-svg > path {
        fill: $input-button-disabled;
      }
    }
    > .pn-select-input:hover {
      cursor: not-allowed;
    }
  }
}
