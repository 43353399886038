pn-checkbox {
  position: relative;
  display: inline-block;
}

pn-checkbox .pn-checkbox {
  position: relative;
  display: flex;
  gap: 0.75em;

  @include pn-transition;

  &-label {
    color: $label-color;
  }

  &-helpertext {
    color: $helpertext-color;
  }

  &-content {
    display: flex;
    flex-direction: column;
    font-weight: 400;
    line-height: 1.5;
    flex: 1;
    margin: 0;
  }

  &-outer {
    width: 1.5em;
    height: 1.5em;
    background-color: $input-background;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: $input-border;
    border-radius: 0.25em;
    cursor: pointer;

    @include pn-transition;

    svg {
      width: 1.25em;
      fill: none;

      polyline {
        stroke: $white;
        transform-origin: 0 0;
        stroke-linecap: round;
        stroke-dasharray: 23;
        stroke-dashoffset: 23;
        transition: stroke-dashoffset $timing $easing 0s;
      }
    }
  }

  &[data-tile] {
    position: relative;
    background-color: $input-background;
    border: $input-border;
    border-radius: $input-border-radius;
    padding: 1em;
    &[data-invalid] {
      border-color: $border-color-error;
    }
  }

  &[data-invalid] .pn-checkbox-outer {
    border-color: $border-color-error;
  }
}

pn-checkbox > input {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;

  &[aria-describedby] + .pn-checkbox[data-tile] .pn-checkbox-label {
    font-weight: 500;
  }

  &:hover + .pn-checkbox {
    > .pn-checkbox-outer {
      background-color: $input-button-background-hover;
      border-color: $border-color-default-active;
    }
    &[data-tile] {
      background-color: $input-button-background-hover;
      border-color: $border-color-default-hover;
      &[data-invalid] {
        background-color: $input-background-error-hover;
        border-color: $border-color-error;
      }
    }
    &[data-invalid] > .pn-checkbox-outer {
      background-color: $input-background-error-hover;
      border-color: $border-color-error;
    }
  }

  &:focus + .pn-checkbox {
    > .pn-checkbox-outer {
      box-shadow: $focus-ring-default;
    }
    &[data-tile] {
      box-shadow: $focus-ring-default;
      border-color: $border-color-default-focus;
      &[data-invalid] {
        box-shadow: $focus-ring-error;
        border-color: $border-color-error;
        > .pn-checkbox-outer {
          box-shadow: none;
        }
      }
      > .pn-checkbox-outer {
        box-shadow: none;
      }
    }
    &[data-invalid] > .pn-checkbox-outer {
      box-shadow: $focus-ring-error;
    }
  }

  &:checked {
    + .pn-checkbox {
      > .pn-checkbox-outer {
        background-color: $input-button-background-box-active;
        border-color: $border-color-default-active;

        svg .pn-checkbox-checkmark-path {
          transition-delay: $timing;
          stroke-dashoffset: 0;
        }
        svg .pn-checkbox-indeterminate-path {
          stroke-dashoffset: 23;
        }
      }

      &[data-tile] {
        background-color: $input-button-background-active;
        border-color: $border-color-default-active;
        &[data-invalid] {
          background-color: $input-background-error-active;
          border-color: $border-color-error-active;
        }
      }

      &[data-invalid] > .pn-checkbox-outer {
        background-color: $input-button-active-error;
        border-color: $border-color-error;
      }
    }

    &:hover + .pn-checkbox {
      &[data-tile] {
        border-color: $input-button-active-hover;
      }
      > .pn-checkbox-outer {
        background-color: $input-button-active-focus;
        border-color: $input-button-active-focus;
      }
      &[data-invalid] {
        border-color: $input-button-active-error-focus;
        > .pn-checkbox-outer {
          background-color: $input-button-active-error-focus;
          border-color: $input-button-active-error-focus;
        }
      }
    }
  }

  &:indeterminate {
    + .pn-checkbox {
      > .pn-checkbox-outer {
        background-color: $input-button-background-box-active;
        border-color: $input-button-background-box-active;
        svg .pn-checkbox-checkmark-path {
          stroke-dashoffset: 23;
        }
        svg .pn-checkbox-indeterminate-path {
          stroke-dashoffset: 0;
        }
      }

      &[data-invalid] {
        > .pn-checkbox-outer {
          background-color: $input-button-active-error;
          border-color: $border-color-error;
        }
      }
    }

    &:hover + .pn-checkbox {
      > .pn-checkbox-outer {
        background-color: $input-button-active;
        border-color: $input-button-active;
      }
      &[data-invalid] > .pn-checkbox-outer {
        background-color: $input-button-active-error-hover;
        border-color: $border-color-error-hover;
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    + .pn-checkbox {
      > .pn-checkbox-outer {
        background-color: $input-background-disabled;
        border-color: $border-color-default;
      }

      &[data-tile] {
        background-color: $input-background-disabled;
        border-color: $border-color-disabled;
        > pn-icon > .pn-icon-svg path {
          fill: $input-color-disabled;
        }
        > .pn-checkbox-content > .pn-checkbox-label {
          color: $input-color-disabled;
        }

        &[data-invalid] {
          background-color: $input-background-disabled;
          border-color: $border-color-disabled;
        }
      }
    }

    &:hover + .pn-checkbox {
      &[data-tile] {
        border-color: $border-color-disabled;
        &[data-invalid] {
          background-color: $input-background-disabled;
          border-color: $border-color-disabled;
        }
        > .pn-checkbox-outer {
          background-color: $input-background-disabled;
          border-color: $input-border-disabled;
        }
      }
      > .pn-checkbox-outer,
      &[data-invalid] > .pn-checkbox-outer {
        background-color: $input-background-disabled;
        border-color: $input-border-disabled;
      }
    }

    &:checked,
    &:checked:hover {
      + .pn-checkbox {
        > .pn-checkbox-outer,
        &[data-invalid] > .pn-checkbox-outer {
          background-color: $input-button-disabled;
          border-color: $input-border-disabled;
        }
      }
    }

    &:indeterminate,
    &:indeterminate:hover,
    &:indeterminate:disabled:hover {
      + .pn-checkbox > .pn-checkbox-outer {
        background-color: $input-button-disabled;
        border-color: $input-border-disabled;
      }
    }
  }
}
