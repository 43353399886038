pn-icon {
  display: inline-block;
  vertical-align: top;
  svg {
    display: block;
    height: 1.5em;
    width: 1.5em;
  }

  &.small {
    svg {
      height: 1em;
      width: 1em;
    }
  }
}
