import { uuidv4 } from '@/globals/helpers';
import { Component, Element, h, Host, Listen, Prop, Watch } from '@stencil/core';

/**
 * The `pn-checkbox` is built with a native `input[type="checkbox"]` in the background.
 * This means you can use native events to listen to the changes.
 *
 * @nativeChange Use the `change` event to listen when the checkbox state is being toggled.
 */
@Component({
  tag: 'pn-checkbox',
  styleUrl: 'pn-checkbox.scss',
})
export class PnCheckbox {
  id: string = `pn-checkbox-${uuidv4()}`;
  idHelpertext: string = `${this.id}-helpertext`;

  @Element() hostElement: HTMLElement;

  /** The checkbox label */
  @Prop() label?: string;
  /** This will be emitted on change and input, like a native checkbox */
  @Prop({ reflect: true }) value!: string;
  /** The name of the checkbox group */
  @Prop() name: string;
  /** Check the radio button. */
  @Prop() checked: boolean = false;
  /** This adds an optional helpertext element under the label */
  @Prop() helpertext?: string;
  /** A unique HTML ID for the checkbox. */
  @Prop() checkboxid: string = this.id;

  /** Disable the radio button. @category State */
  @Prop() disabled: boolean = false;
  /** Set the checkbox as required. @category State */
  @Prop() required: boolean = false;
  /** If set to true, color scheme will turn red, indicating that there is an issue or incorrect input related the checkbox. @category State */
  @Prop() invalid: boolean = false;
  /** Sets the checkbox to an indeterminate state, allowing for a mixed or intermediate checkbox state. @category State */
  @Prop({ mutable: true }) indeterminate: boolean = false;

  /** If set to true, this checkbox turns into a checkbox-tile. @category Tile */
  @Prop() tile: boolean = false;
  /** Add an icon on the right of your checkbox tile. The `tile` prop must be `true` for the icon to work. @category Tile */
  @Prop() icon?: string = null;

  @Watch('checkboxid')
  handleId() {
    this.idHelpertext = `${this.checkboxid}-helpertext`;
  }

  @Listen('change')
  handlechange() {
    this.indeterminate = false;
  }

  componentWillLoad() {
    this.handleId();
  }

  render() {
    return (
      <Host>
        <input
          type="checkbox"
          id={this.checkboxid}
          value={this.value}
          name={this.name || this.checkboxid}
          disabled={this.disabled}
          checked={this.checked}
          required={this.required}
          indeterminate={this.indeterminate}
          aria-describedby={this.helpertext && this.idHelpertext}
        />
        <div class="pn-checkbox" data-tile={this.tile} data-invalid={this.invalid}>
          <div class="pn-checkbox-outer">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
              <polyline class="pn-checkbox-checkmark-path" points="4,12 9,17 20,6" stroke-width="3" />
              <polyline class="pn-checkbox-indeterminate-path" points="4,12 20,12" stroke-width="3" />
            </svg>
          </div>

          {(!!this.label || !!this.helpertext) && (
            <p class="pn-checkbox-content">
              {!!this.label && (
                <label htmlFor={this.checkboxid} class="pn-checkbox-label">
                  {this.label}
                </label>
              )}

              {!!this.helpertext && (
                <span id={this.idHelpertext} class="pn-checkbox-helpertext">
                  {this.helpertext}
                </span>
              )}
            </p>
          )}

          {this.tile && this.icon && <pn-icon icon={this.icon} color="gray900" />}
        </div>
      </Host>
    );
  }
}
