pn-radio-button {
  position: relative;
  display: inline-block;
}

pn-radio-button .pn-radio {
  position: relative;
  display: flex;
  gap: 0.75em;

  &-label {
    color: $label-color;
  }

  &-helpertext {
    color: $helpertext-color;
  }

  &-content {
    margin: 0;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &-container {
    display: flex;
    gap: 0.75em;
    padding: 0;
  }

  &-outer {
    background-color: $input-background;
    border: $input-border;
    border-radius: 50%;
    height: 1.5em;
    width: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    @include pn-transition('border-color, background-color, box-shadow');
  }

  &-inner {
    transform: scale(0);
    height: 0.75em;
    width: 0.75em;
    background-color: $input-button-background-box-active;
    border-radius: 50%;
    transform-origin: center center;
    @include pn-transition('background-color, transform');
  }

  &[data-tile] {
    padding: 1em;
    border: $input-border;
    border-radius: $border-radius;
    background-color: $input-background;

    @include pn-transition('border-color, background-color, box-shadow');
  }

  &[data-invalid] {
    border-color: $border-color-error;
    .pn-radio-outer {
      border-color: $border-color-error;
    }
    .pn-radio-inner {
      background-color: $input-button-active-error;
    }
  }
}

pn-radio-button > input[type='radio'] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  margin: 0;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;

  &[aria-describedby] + .pn-radio[data-tile] .pn-radio-label {
    font-weight: 500;
  }

  &:hover + .pn-radio {
    &[data-tile] {
      border-color: $border-color-default-hover;
      background-color: $input-button-background-hover;
      .pn-radio-outer {
        background-color: $input-button-background-hover;
      }
      &[data-invalid] {
        border-color: $border-color-error;
        background-color: $input-background-error-hover;
        .pn-radio-outer {
          background-color: $input-background-error-hover;
        }
      }
    }
    &[data-invalid] {
      .pn-radio-outer {
        border-color: $border-color-error;
        background-color: $input-background-error-hover;
      }
    }

    .pn-radio-outer {
      border-color: $input-button-background-box-active;
      background-color: $input-button-background-hover;
    }
  }

  &:checked:hover + .pn-radio {
    &[data-tile] {
      &[data-invalid] {
        border-color: $input-button-active-error-hover;
      }
    }
    &[data-invalid] > .pn-radio-container > .pn-radio-outer {
      border-color: $input-button-active-error-hover;
    }
  }

  &:disabled:hover {
    + .pn-radio {
      &[data-invalid] {
        border-color: $border-color-disabled;
      }
      > .pn-radio-container > .pn-radio-outer {
        background-color: $input-background-disabled;
      }
    }
  }
  &:disabled:checked:hover {
    + .pn-radio {
      &[data-invalid] {
        border-color: $border-color-disabled;
        > .pn-radio-container > .pn-radio-outer {
          background-color: $input-background-disabled;
          > .pn-radio-inner {
            background-color: $input-button-disabled;
          }
        }
      }
      > .pn-radio-container > .pn-radio-outer {
        background-color: $input-background-disabled;
      }
    }
  }

  &:focus + .pn-radio .pn-radio-outer,
  &:active + .pn-radio .pn-radio-outer {
    box-shadow: $focus-ring-default;
  }

  &:focus + .pn-radio[data-invalid] .pn-radio-outer,
  &:active + .pn-radio[data-invalid] .pn-radio-outer {
    box-shadow: $focus-ring-error;
  }

  &:focus + .pn-radio[data-tile],
  &:active + .pn-radio[data-tile] {
    border-color: $border-color-default-focus;
    box-shadow: $focus-ring-default;
    &[data-invalid] {
      border-color: $input-button-active-error;
      box-shadow: $focus-ring-error;
    }
    > .pn-radio-container > .pn-radio-outer {
      box-shadow: none;
    }
  }

  &:checked + .pn-radio {
    &[data-tile] {
      border-color: $border-color-default-active;
      background-color: $input-button-background-active;
      &[data-invalid] {
        border-color: $border-color-error-active;
        background-color: $input-background-error-active;
      }
    }
    > .pn-radio-container > .pn-radio-outer {
      border-color: $border-color-default-active;
      > .pn-radio-inner {
        transform: scale(1);
        background-color: $input-button-background-box-active;
      }
    }
    &[data-invalid] {
      > .pn-radio-container > .pn-radio-outer {
        border-color: $border-color-error;
        > .pn-radio-inner {
          background-color: $border-color-error;
        }
      }
    }
  }

  &:disabled {
    cursor: not-allowed;
    &:active + .pn-radio {
      box-shadow: none;
    }
  }

  &:disabled + .pn-radio {
    &[data-tile],
    &[data-tile][data-invalid] {
      background-color: $input-background-disabled;
      border-color: $border-color-disabled;
      > pn-icon > .pn-icon-svg path {
        fill: $input-color-disabled;
      }
      > .pn-radio-content > .pn-radio-label {
        color: $input-color-disabled;
      }
    }

    > .pn-radio-container > .pn-radio-outer {
      background-color: $input-background-disabled;
      border-color: $input-border-disabled;
      box-shadow: none;
      > .pn-radio-inner {
        background-color: $input-button-disabled;
      }
    }
  }

  &:disabled:checked + .pn-radio {
    &[data-invalid],
    &[data-tile],
    &[data-tile][data-invalid] {
      > .pn-radio-container > .pn-radio-outer {
        background-color: $input-background-disabled;
        border-color: $input-border-disabled;
        box-shadow: none;
        > .pn-radio-inner {
          background-color: $input-button-disabled;
        }
      }
    }
  }
}
