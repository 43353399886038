$duration: 0.3s;

pn-tab {
  flex-shrink: 0;
  scroll-snap-align: center;
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 0.5em;
}
pn-tab .pn-tab {
  margin: 1em 0.25em;
  padding: 0 0.25em;
  border: none;
  font-family: inherit;
  background-color: transparent;
  cursor: pointer;
  font-size: 1em;
  color: $gray900;
  font-weight: 500;
  outline: none;
  display: flex;
  gap: 0.5em;
  align-items: center;
  justify-content: center;
  transition-property: color, box-shadow;
  transition-duration: $duration;
  transition-timing-function: $easing;
  border-radius: 0.5em;
  text-decoration: none;

  @include pn-ripple($blue700);

  > span {
    line-height: 1.5em;
  }

  > pn-icon > .pn-icon-svg {
    path,
    polygon {
      transition: fill $duration $easing;
      fill: $gray900;
    }
  }

  &[data-focus] {
    position: relative;
    overflow: hidden;
    &:focus {
      background-color: $white;
      box-shadow: $focus-ring-default;
    }
  }

  &[aria-selected='true'],
  &[aria-current='page'] {
    color: $blue700;

    > pn-icon > .pn-icon-svg {
      path,
      polygon {
        fill: $blue700;
      }
    }
    &:focus {
      color: $blue900;
      pn-icon > .pn-icon-svg {
        path,
        polygon {
          fill: $blue900;
        }
      }
    }
  }
}
