pn-spinner {
  height: var(--size);
  width: var(--size);
  display: block;

  svg {
    animation: rotate 1s infinite;
    display: block;

    .circle {
      stroke: $blue700;
      transform-origin: center center;
      transform: rotate(-310deg);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-dasharray: 64;
      animation: loading 2s infinite;
    }

    .dot {
      fill: $blue700;
    }
  }

  &.light {
    .circle {
      stroke: $white;
    }

    .dot {
      fill: $white;
    }
  }

  svg,
  svg .circle {
    animation-timing-function: cubic-bezier(0.5, 0, 0.5, 1);
  }
}

@keyframes loading {
  0% {
    stroke-dashoffset: 55;
  }
  50% {
    stroke-dashoffset: 12;
  }
  100% {
    stroke-dashoffset: 55;
  }
}

@keyframes rotate {
  from {
    transform: rotate(-180deg) scaleX(-1);
  }
  to {
    transform: rotate(180deg) scaleX(-1);
  }
}
