export const translations = {
  SHOW: {
    sv: 'Visa',
    en: 'Show',
    da: 'At vise',
    fi: 'Näytä',
    no: 'Vise',
  },
  HIDE: {
    sv: 'Dölj',
    en: 'Hide',
    da: 'Skjule',
    fi: 'Piilottaa',
    no: 'Gjemme seg',
  },
  CLEAR: {
    sv: 'Rensa',
    en: 'Clear',
    da: 'Klar',
    fi: 'Asia selvä',
    no: 'Klar',
  },
}