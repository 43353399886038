import { Component, Element, Prop, h, Host, State } from '@stencil/core';
import { PnLanguages, uuidv4, en, awaitTopbar } from '@/index';

import { alert_exclamation_circle, check, close, preview_on, preview_off } from 'pn-design-assets/pn-assets/icons.js';

import { translations } from './translations';

/**
 * The `pn-input` is a regular `input` but styled. This means you can use native events to listen to the changes.
 *
 * @nativeInput Use the `input` event to listen to content being modified by the user.
 */
@Component({
  tag: 'pn-input',
  styleUrl: 'pn-input.scss',
})
export class PnInput {
  id: string = uuidv4();
  idInput: string = `pn-input-${this.id}`;
  idHelper = `${this.idInput}-helper`;

  mo: MutationObserver;

  @Element() hostElement: HTMLElement;

  @State() offsetLeft: number = 0;
  @State() offsetRight: number = 0;

  @State() showPassword: boolean = false;
  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  /** Text label placed above the input field. */
  @Prop() label!: string;
  /** Text message placed underneath the input field. */
  @Prop() helpertext?: string;
  /**
   * Provide a unique HTML id to connect the input with the label.
   * A unique uuid ID will be generated if this field is left empty.
   **/
  @Prop() inputid: string = this.idInput;
  /** Set the value of the input. */
  @Prop({ mutable: true }) value: string = '';
  /**
   * Set the language manually for the translations of show/hide/clear button text.
   * Not needed if you have the pntopbar on the page.
   **/
  @Prop() language?: PnLanguages = null;

  /**
   * Select an icon to display before the input field value.
   * `icon` takes precedence over the `text-prefix` prop.
   *
   * @see {@link textPrefix}
   * @category Visual
   **/
  @Prop() icon: string;
  /**
   * Set a small text before the input field value.
   * Cannot be used together with an `icon` at the same time.
   *
   * @see {@link icon}
   * @see {@link textSuffix}
   * @category Visual
   **/
  @Prop() textPrefix: string;
  /**
   * Set a small text after the input field value.
   * Cannot be used with the `text-prefix` prop at the same time.
   *
   * @see {@link textPrefix}
   * @category Visual
   **/
  @Prop() textSuffix: string;

  /** pn-input supports: `text`, `password`, `url`, `tel`, `search`, `number` & `email`. @category HTML input */
  @Prop({ mutable: true }) type?: 'text' | 'password' | 'url' | 'tel' | 'search' | 'number' | 'email' | '' = 'text';
  /**
   * HTML input name. Setting a name will help the browser understand what type of data the input have
   * and can better assist with autofill data based on previous entires much better.
   *
   * @category HTML input
   **/
  @Prop() name?: string;
  /**
   * Provide a placeholder text. Remember that this is no replacement for a label.
   * The placeholder should be a nice addition to the label/helpertext, not important information.
   *
   * @category HTML input
   **/
  @Prop() placeholder?: string;
  /**
   * Let the browser know what type of autocorrects the input should use.
   * Works much better if a `name` and `inputid` is supplied.
   * @see {@link name}
   * @see {@link inputid}
   *
   * @category HTML input */
  @Prop() autocomplete?: string;
  /** The maximum number of characters the user should be able to add, also adds a visible counter. @category HTML input */
  @Prop() maxlength: number;
  /**
   * Hint the browser about what type of virtual keyboard should be used.
   * The browser will be able to decide this on its own most of the time,
   * especially if you use the `type`, `name` and `inputid` props.
   *
   * Leave empty or with a `''` value if you want the browsers default behaviour (`text`).
   *
   * @category HTML input
   **/
  @Prop() inputmode?: 'text' | 'none' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
  /** Point to a datalist element for this input. @category HTML input */
  @Prop() list?: string;
  /** Pattern prop. @category HTML input*/
  @Prop() pattern?: string;
  /** Set the `min` value of the `number` input. @category HTML input */
  @Prop() min?: number | string;
  /** Set the `max` value of the `number` input. @category HTML input */
  @Prop() max?: number | string;
  /** Set a `step` for the number input. @category HTML input */
  @Prop() step?: number | string;
  /** While you can use the `aria-label`, using a `label` is far more accessible. @category HTML input */
  @Prop() arialabel?: string;
  /** Set the ID of what this input controls. @category HTML input */
  @Prop() ariacontrols?: string;

  /** Set the input as `required`. @category State */
  @Prop() required: boolean = false;
  /** Set the input as `disabled`. @category State */
  @Prop() disabled: boolean = false;
  /** Set the input as `readonly`. @category State */
  @Prop() readonly: boolean = false;

  /** Set the input as `valid`. Provides a green color and a check icon. @category Validation */
  @Prop() valid?: boolean = false;
  /**
   * Set the input as `invalid`. Provides a red color and red warning icon.
   * @see{@link error Provide an error message.}
   * @category Validation
   **/
  @Prop() invalid: boolean = false;
  /**
   * Set the input as `invalid` and display an error message (applies the same style as `invalid`).
   *
   * Error message; will take precedence over helpertext if both are provided.
   * @see{@link invalid Set invalid without an error message.}
   * @category Validation
   **/
  @Prop() error?: string;

  async componentWillLoad() {
    if (this.mo) this.mo.disconnect();

    this.mo = new MutationObserver(() => this.handleOffset());

    this.mo.observe(this.hostElement, {
      subtree: true,
      childList: true,
    });

    this.handleOffset();

    if (this.inputid !== this.idInput) {
      this.idHelper = `${this.inputid}-helper`;
    }

    if (this.language === null) await awaitTopbar(this.hostElement);
  }

  setVal(event: InputEvent) {
    const target = event.composedPath?.()[0] as HTMLInputElement;
    this.value = target.value;
  }

  clearVal() {
    const inputClear = new InputEvent('input', { bubbles: true, data: '' });

    const input = this.hostElement.querySelector('input');

    this.value = '';

    input.focus();
    input.value = this.value;
    input.dispatchEvent(inputClear);
  }

  handleOffset() {
    const prefix = this.hostElement.querySelector('.pn-input-eyecandy[data-prefix]');
    const suffix = this.hostElement.querySelector('.pn-input-eyecandy[data-suffix]');

    const left = prefix?.clientWidth ? prefix.clientWidth + 8 : 0;
    const right = suffix?.clientWidth ? suffix.clientWidth + 8 : 0;

    this.offsetLeft = left;
    this.offsetRight = right;
  }

  translate(prop: string): string {
    return translations?.[prop]?.[this.language || en];
  }

  hasHelperText(): boolean {
    return this.helpertext?.length > 0;
  }

  hasErrorMessage(): boolean {
    return this.error?.length > 0;
  }

  getInputType(): string {
    const types = ['text', 'password', 'url', 'tel', 'search', 'number', 'email'];

    return types.includes(this.type) && !this.showPassword ? this.type : 'text';
  }

  isPassword(): boolean {
    if (this.disabled) return false;
    return this.type === 'password' || (this.type === 'text' && this.showPassword);
  }

  isSearch(): boolean {
    if (this.disabled) return false;
    return this.type === 'search' && !!this.value?.length;
  }

  hasError(): boolean {
    return this.hasErrorMessage() || this.invalid;
  }

  hasMessage(): boolean {
    return this.hasHelperText() || this.hasErrorMessage();
  }

  stateDisplay(): boolean {
    return this.valid || this.hasError();
  }

  stateIcon(): string {
    if (this.valid) return check;
    return alert_exclamation_circle;
  }

  stateColor(): string {
    if (this.valid) return 'green700';
    return 'warning';
  }

  showPrefix(): boolean {
    return this.textPrefix && !this.icon;
  }

  showSuffix(): boolean {
    return !this.showPrefix() && !!this.textSuffix?.length && !this.isPassword() && this.type !== 'search';
  }

  render(): HTMLPnInputElement {
    return (
      <Host>
        <div class="pn-input" data-valid={this.valid} data-error={this.hasError()}>
          {(this.label || this.maxlength >= 1) && (
            <label htmlFor={this.inputid} class="pn-input-label">
              {this.label && <span>{this.label}</span>}
              {this.maxlength >= 0 && <span>{`${this.value.length}/${this.maxlength}`}</span>}
            </label>
          )}
          <div class="pn-input-group">
            <input
              type={this.getInputType()}
              id={this.inputid}
              class="pn-input-element"
              name={this.name}
              placeholder={this.placeholder}
              autocomplete={this.autocomplete}
              maxlength={this.maxlength}
              list={this.list}
              pattern={this.pattern}
              min={this.min}
              max={this.max}
              step={this.step}
              value={this.value}
              inputmode={this.inputmode}
              disabled={this.disabled}
              required={this.required}
              readonly={this.readonly}
              aria-label={this.arialabel}
              aria-describedby={this.hasMessage() ? this.idHelper : null}
              aria-controls={this.ariacontrols}
              style={{
                '--pn-input-offset-left': `${this.offsetLeft}px`,
                '--pn-input-offset-right': `${this.offsetRight}px`,
              }}
              onInput={e => this.setVal(e)}
            />

            <div class="pn-input-eyecandy" data-prefix>
              {!!this.icon && <pn-icon icon={this.icon} aria-hidden="true" />}
              {this.showPrefix() && <span class="pn-input-text">{this.textPrefix}</span>}
            </div>

            <div class="pn-input-eyecandy" data-suffix>
              {this.showSuffix() && <span class="pn-input-text">{this.textSuffix}</span>}

              {this.stateDisplay() && <pn-icon icon={this.stateIcon()} color={this.stateColor()} />}

              {this.isPassword() && (
                <pn-button
                  icon={this.showPassword ? preview_on : preview_off}
                  tooltip={this.translate(this.showPassword ? 'HIDE' : 'SHOW')}
                  small
                  appearance="light"
                  variant="borderless"
                  onClick={() => this.togglePassword()}
                />
              )}
              {this.isSearch() && (
                <pn-button
                  icon={close}
                  tooltip={this.translate('CLEAR')}
                  small
                  appearance="light"
                  variant="borderless"
                  onClick={() => this.clearVal()}
                />
              )}
            </div>
          </div>

          {this.hasMessage() && (
            <p class="pn-input-helper" id={this.idHelper} role={this.hasErrorMessage() ? 'alert' : null}>
              <span>{this.hasHelperText() ? this.helpertext : this.error}</span>
            </p>
          )}
        </div>
      </Host>
    );
  }
}
