import {
  Component,
  h,
  Host,
  Element,
  Watch,
  Prop,
  Listen,
  State,
  EventEmitter,
  Event,
  forceUpdate,
} from '@stencil/core';
import { translations } from './translations';
import { en } from '@/globals/helpers';
import { alert_exclamation_circle, angle_down } from 'pn-design-assets/pn-assets/icons.js';
import { uuidv4, awaitTopbar } from '@/globals/helpers';
import { PnLanguages } from '@/globals/types';

/**
 * The width of the `pn-select` is decided by the label, placeholder, helpertext or the content of the value selected.
 *
 * There is a min-width of 5em. It is 12em if the select is a checkbox variant. This is to account for the checkboxes inside the list.
 *
 * The best option is to set your desired width or let the select fill out its parent container so the width never changes between selections.
 */
@Component({
  tag: 'pn-select',
  styleUrl: 'pn-select.scss',
})
export class PnSelect {
  elSelect: HTMLDivElement;
  elList: HTMLDivElement;
  elOptions: HTMLPnOptionElement[];
  elSearch: HTMLPnInputElement;

  idSelect: string = `pn-select-${uuidv4()}`;
  idLabel: string = `${this.idSelect}-label`;
  idOptions: string = `${this.idSelect}-options`;
  idText: string = `${this.idSelect}-text`;
  idSearch: string = `${this.idSelect}-search`;
  idSr: string = `${this.idSelect}-sr-only`;

  tapCharacter?: string;
  tapCount?: number = 0;

  movingTimer: NodeJS.Timeout;
  srTimer: NodeJS.Timeout;

  mo: MutationObserver;

  @Element() hostElement: HTMLElement;

  /** Layout state. */
  @State() open: boolean = false;
  @State() moving: boolean = false;
  @State() upwards: boolean = false;

  /** Helps keeping track of checkboxes. */
  @State() boxes: { value: string; label: string }[] = [];
  @State() boxesLength?: number;

  @State() searchResult?: number;
  @State() searchHelper: string;

  @State() activeDescendant: string;
  @State() showSelection: boolean = false;

  @State() valueLabel: string;

  /** Label placed above the select */
  @Prop() label!: string;
  /**
   * The select input value. This will auto select the `pn-option` with the same value.
   * You need to manually the the `selected` prop on the nested `pn-option` elements if you are using checkboxes.
   */
  @Prop({ reflect: true, mutable: true }) value: string = '';
  /**
   * This is what will be shown on load if no value is used.
   * The `placeholder` will override the default text used if you have `checkboxes`.
   */
  @Prop() placeholder?: string;
  /** Display a helper text underneath the select */
  @Prop() helpertext?: string;
  /** Select HTML id */
  @Prop() selectId: string = this.idSelect;
  /** Display an icon to the left of the select input */
  @Prop() icon?: string;

  /** Manually set the language, not needed if you have the pnTopbar available */
  @Prop() language?: PnLanguages = null;
  /**
   * Add a generic "Please select option" with no value. This text is translated according to the topbar or `language` prop
   */
  @Prop() emptyOption: boolean = false;
  /**
   * Allow multiselection on the select component.
   * You also need to set the `checkbox` prop on **all** nested `pn-option` elements
   */
  @Prop({ reflect: true }) checkbox: boolean = false;

  /**
   * Allow the user to search among the options. This will display a search input above the options
   * @category Search
   */
  @Prop() search?: boolean = false;
  /**
   * aria-label for the search input and acts as the placeholder. Default is `Search`
   * @category Search
   */
  @Prop() searchLabel?: string;
  /**
   * The text displayed if the search yeilds no results. Default is `No options found`
   * @category Search
   */
  @Prop() searchNoResults?: string;
  /**
   * Search HTML id
   * @category Search
   */
  @Prop() searchId?: string = this.idSearch;

  /**
   * Set the select as required.
   * As this is a non-native input, you will need to deal with the required state manually in your code.
   * Look for the `aria-required="true"` when creating these conditions.
   * @category Validation
   */
  @Prop() required?: boolean = false;
  /**
   * Set the select as read only, allowing you to open and view your selection without modifying it.
   * @category Validation
   */
  @Prop() readonly?: boolean = false;
  /**
   * Disable the select
   * @category Validation
   */
  @Prop() disabled?: boolean = false;
  /**
   * Trigger the invalid state
   * @category Validation
   */
  @Prop() invalid?: boolean = false;
  /**
   * Display an error message and trigger the invalid state.
   * @category Validation
   */
  @Prop() error?: string;

  @Watch('open')
  setMaxHeight() {
    this.moving = true;

    clearTimeout(this.movingTimer);

    this.movingTimer = setTimeout(() => {
      this.moving = false;
    }, 300);

    if (!this.open) {
      this.activeDescendant = null;
      this.resetFocus();

      this.displaySrText();

      return;
    }

    const { bottom, top, width } = this.elSelect.getBoundingClientRect();

    this.hostElement.style.setProperty('--width', `${Math.ceil(width)}px`);

    const oneEm = 16;
    const maxHeightPx = 960;
    /*
     * Take the inner window height and subtract the elements bottom and 1em (16px)
     * If the max height of this calculation is less than the space above the element, open it upwards.
     * Do another calculation with the top value - 1em.
     */
    const mhTop = window.innerHeight - bottom - oneEm;
    const mhBottom = top - oneEm;

    /**
     * Always point downwards, unless the space to the bottom is less than 10em
     */
    this.upwards = 160 > mhTop && mhBottom > mhTop;

    const maxHeight = this.upwards ? mhBottom : mhTop;
    const defaultMaxHeight = maxHeight > maxHeightPx ? maxHeightPx : maxHeight;

    this.elList.style.setProperty('--max-height', `${Math.ceil(defaultMaxHeight)}px`);
  }

  @Watch('search')
  resetOptionVisibility() {
    if (!this.search) this.elOptions.forEach(element => element.removeAttribute('hidden'));
  }

  /**
   * This event will be emitted when an option is selected.
   *
   * Contains the `label`, `value` and if the option is `selected`.
   * The `items` property is only there if its a checkbox. It contains an array of all selected values as strings.
   */
  @Event() selectOption: EventEmitter<{ label: string; value: string; selected: boolean; items?: string[] }>;

  async componentWillLoad() {
    if (this.language) return;
    await awaitTopbar(this.hostElement);
  }

  componentDidLoad() {
    if (this.mo) this.mo.disconnect();

    this.mo = new MutationObserver(() => {
      forceUpdate(this.hostElement);
      this.setElements();
      this.setCheckboxValues();
    });

    this.mo.observe(this.hostElement, { childList: true, subtree: true, attributeFilter: ['selected'] });

    this.setElements();
    this.setCheckboxValues();

    const { label, value } = this.elOptions.find(element => element.value === this.value) || {};

    this.valueLabel = label;
    this.value = value;
    this.setParentValue();
  }

  @Listen('optionClick')
  selectOptionHandler(
    event?: CustomEvent | { detail: { value: string; label: string; selected?: boolean }; stopPropagation: Function },
  ) {
    event?.stopPropagation?.();

    if (this.blockSelect()) {
      this.toggleOpen(false);
      this.setFocus();
      return;
    }

    const { value, label, selected } = event.detail;

    this.valueLabel = label;
    this.value = value;

    this.setParentValue();

    if (this.checkbox) this.setCheckboxValue({ value, label, selected });
    else this.toggleOpen(false);

    const data = event.detail;
    if (this.checkbox) data.items = this.boxes.map(({ value }) => value);
    this.selectOption.emit(data);

    this.setFocus();
  }

  @Listen('optionFocus')
  handleFocusOption(event: CustomEvent) {
    event.stopPropagation();

    const { id, blur } = event.detail;

    if (id) {
      this.activeDescendant = id;
      return;
    }

    if (blur) this.toggleOpen(false);
    else this.setFocus();
  }

  @Listen('optionNav')
  handleNavOption({ detail }: { detail: KeyboardEvent }) {
    this.arrowKeyNav(detail);
  }

  translate(prop: string): string {
    return translations?.[prop]?.[this.language || en];
  }

  getRect(element: HTMLElement): DOMRect {
    return element.getBoundingClientRect();
  }

  isVisible(element: HTMLElement): boolean {
    const { top, bottom, height } = this.getRect(element);
    const container = this.getRect(this.elList);

    const goingUp = top <= container.top;
    const isTopOutsideOverflow = container.top - top + 24 <= height;
    const isBottomOutsideOverflow = bottom - container.bottom <= height - 24;

    return goingUp ? isTopOutsideOverflow : isBottomOutsideOverflow;
  }

  setFocus() {
    this.elSelect.focus();
  }

  setParentValue() {
    this.elOptions.forEach(option => (option.parentValue = this.value));
  }

  blockSelect() {
    return this.disabled || this.readonly;
  }

  hasError() {
    return this.invalid || !!this.error;
  }

  hasMessage() {
    return !!this.helpertext || !!this.error || this.checkbox;
  }

  displaySrText() {
    clearTimeout(this.srTimer);
    this.showSelection = true;
    this.srTimer = setTimeout(() => {
      this.showSelection = false;
    }, 2000);
  }

  toggleOpen(state?: boolean) {
    this.open = state ?? !this.open;
  }

  setElements() {
    this.elSelect = this.hostElement.querySelector<HTMLDivElement>('.pn-select-content');
    this.elList = this.hostElement.querySelector<HTMLDivElement>('.pn-select-options');
    this.elOptions = Array.from(this.elList.querySelectorAll<HTMLPnOptionElement>('pn-option')) || [];
    this.elSearch = this.hostElement.querySelector<HTMLPnInputElement>('pn-input');

    if (!this.elOptions.length) throw new Error('No options found, you must provide `pn-option` elements.');
    this.elOptions.forEach(
      option =>
        (!option.label || (!option.value && !option.dataset.empty)) &&
        console.warn(`Option is missing the label or value property:`, option),
    );
  }

  async arrowKeyNav(e: KeyboardEvent) {
    const { key, code } = e;

    const printableCharacter = key.length === 1 && /^[a-z]+$/i.test(key);

    const arrowUp = code === 'ArrowUp';
    const arrowDown = code === 'ArrowDown';
    const pageUp = code === 'PageUp';
    const pageDown = code === 'PageDown';
    const home = code === 'Home';
    const end = code === 'End';
    const tab = code === 'Tab';
    const space = code === 'Space';
    const enter = code === 'Enter';
    const escape = code === 'Escape';

    const triggers = [arrowUp, arrowDown, pageUp, pageDown, home, end, tab, space, enter, escape, printableCharacter];

    if (!triggers.some(item => item)) return;

    const moving = arrowUp || arrowDown || pageUp || pageDown || home || end || printableCharacter;
    const select = tab || space || enter;

    if (moving || space || enter || printableCharacter) e.preventDefault();

    if (escape) {
      this.toggleOpen(false);
      return;
    }

    if (select) {
      this.setValue(e, tab);
      return;
    }

    if (moving && !this.open) {
      this.toggleOpen(true);
    }

    if (printableCharacter) {
      this.tapCount = this.tapCharacter === key ? this.tapCount + 1 : 0;
      this.tapCharacter = key;
    }

    const element = this.findElement({
      next: arrowDown || pageDown,
      previous: arrowUp || pageUp,
      first: home,
      last: end,
      search: printableCharacter ? key : null,
      searchIndex: printableCharacter ? this.tapCount : null,
    });

    if (element) {
      this.resetFocus(element, pageUp || pageDown);
      e.preventDefault();
    }
  }

  setValue(e: KeyboardEvent, tab: boolean) {
    if (tab && this.search && this.open) {
      e.preventDefault();
      this.elSearch.querySelector('input').focus();
      return;
    }

    const element = this.elOptions?.find(item => item.focusEl);
    const option = element?.querySelector<HTMLDivElement>('.pn-option');

    if (option) option.click();
    else {
      !this.open && this.resetFocus(this.elOptions?.[0]);
      this.toggleOpen();
    }
  }

  setCheckboxValue({ value, label, selected }) {
    this.elOptions.find(({ value }) => value === this.value).selected = selected;

    if (selected) this.boxes = [{ value, label }, ...this.boxes];
    else this.boxes = [...this.boxes.filter(box => box.value !== value)];

    this.boxesLength = this.boxes.length;
  }

  setCheckboxValues() {
    if (!this.checkbox) return;
    const newBoxes =
      this.elOptions
        .map(element => (element.selected ? { value: element.value, label: element.label } : null))
        .filter(Boolean) || [];

    this.boxes = [...newBoxes];
    this.boxesLength = this.boxes.length;
  }

  findElement({
    next,
    previous,
    first,
    last,
    search,
    searchIndex,
  }: {
    next?: boolean;
    previous?: boolean;
    first?: boolean;
    last?: boolean;
    search?: string;
    searchIndex?: number;
  }): HTMLPnOptionElement {
    const options = this.elOptions.filter(({ hidden, disabled }) => !hidden && !disabled);
    const currentIndex = options.findIndex(({ value }) => value === this.value);
    const currentFocusIndex = options.findIndex(({ focusEl }) => focusEl);

    const optionIndex = currentFocusIndex !== -1 ? currentFocusIndex : currentIndex;

    if (next || first) {
      const nextElement = next && options.find((_item, index) => index === optionIndex + 1);
      return nextElement || this.elOptions[0];
    }

    if (previous || last) {
      const previousElement = previous && options.find((_item, index) => index === optionIndex - 1);
      return previousElement || options[options.length - 1];
    }

    if (search) {
      const searchList = options.filter(({ label }) => label.toLowerCase().startsWith(search));
      const foundOption = !!searchList?.[searchIndex];
      const sIndex = foundOption ? searchIndex : 0;

      if (!foundOption) this.tapCount = 0;

      const searchElement = searchList[sIndex];
      return searchElement;
    }
  }

  resetFocus(element?: HTMLPnOptionElement, control: boolean = false) {
    this.elOptions.forEach(option => option.removeAttribute('focus-el'));

    if (!element) return;

    element.setAttribute('focus-el', 'true');
    if (control) element.querySelector<HTMLDivElement>('.pn-option').click();

    if (this.moving) setTimeout(() => this.scrollToOption(element), 300);
    else this.scrollToOption(element);
  }

  scrollToOption(element: HTMLPnOptionElement) {
    if (!this.open) return;
    if (!this.isVisible(element)) element.scrollIntoView({ block: 'end', inline: 'nearest', behavior: 'smooth' });
  }

  searchOptions(e: InputEvent) {
    const { value } = e.target as HTMLInputElement;
    const searchString = value.toLowerCase();

    this.elOptions.forEach(option => {
      const optionLabel = option?.label?.toLowerCase();
      if (optionLabel.match(searchString)) option.removeAttribute('hidden');
      else option.setAttribute('hidden', 'true');
    });

    this.searchResult = this.elOptions.filter(({ hidden }) => !hidden).length;
    this.searchHelper = value === '' ? '' : `${this.searchResult} ${this.translate('RESULTS_FOUND')}`;
  }

  handleBlur(e: FocusEvent) {
    const target = e.relatedTarget as HTMLDivElement;

    if (this.searchId === target?.id) {
      return target.focus();
    }

    if ([this.selectId, this.idOptions].includes(target?.id)) {
      return this.setFocus();
    }

    this.toggleOpen(false);
  }

  handleSearchTab(e: KeyboardEvent) {
    if (e.code === 'Tab' && !this.checkbox) {
      e.preventDefault();
      this.setFocus();
    }
    if (/Arrow/.test(e.code)) {
      this.arrowKeyNav(e);
      this.setFocus();
    }
  }

  handleSearchBlur() {
    const select = this.hostElement.matches(':focus-within');
    if (!select) this.toggleOpen(false);
  }

  handleLabel() {
    if (this.disabled) return;
    this.setFocus();
  }

  srOnlyText(): string {
    if (this.checkbox) {
      const values = this.boxes.map(({ label }) => label).join(', ');
      return values;
    }
    return this.value;
  }

  selectText() {
    const defaultText = this.placeholder || this.translate('SELECT_AN_OPTION');
    if (this.checkbox) return this.boxesLength ? this.boxes.map(({ label }) => label).join(', ') : defaultText;
    if (!!this.value) return this.valueLabel;
    return defaultText;
  }

  isPlaceholder() {
    return this.value === '' || this.boxesLength === 0;
  }

  render() {
    return (
      <Host>
        <div class="pn-select" data-error={this.hasError()} data-disabled={this.disabled}>
          {this.label && (
            <label id={this.idLabel} class="pn-select-label" onClick={() => this.handleLabel()}>
              <span>{this.label}</span>
            </label>
          )}
          <div class="pn-select-input">
            <button
              type="button"
              role="combobox"
              id={this.selectId}
              class="pn-select-content"
              tabindex={this.disabled ? '-1' : '0'}
              aria-controls={this.idOptions}
              aria-haspopup="listbox"
              aria-expanded={this.open.toString()}
              aria-multiselectable={this.checkbox ? 'true' : null}
              aria-labelledby={this.idLabel}
              aria-describedby={this.hasMessage() && this.idText}
              aria-required={this.required ? 'true' : null}
              aria-activedescendant={this.activeDescendant}
              aria-readonly={this.readonly ? 'true' : null}
              aria-disabled={this.disabled ? 'true' : null}
              data-upwards={this.upwards}
              data-total={this.checkbox && this.boxesLength}
              data-placeholder={this.isPlaceholder()}
              onClick={() => this.toggleOpen()}
              onKeyDown={e => this.arrowKeyNav(e)}
              onBlur={e => this.handleBlur(e)}
            >
              {this.icon && <pn-icon icon={this.icon} color="gray900" />}
              <span>{this.selectText()}</span>
              {this.hasError() && <pn-icon class="pn-select-icon" icon={alert_exclamation_circle} color="warning" />}
              <pn-icon class="pn-select-icon" icon={angle_down} color="blue700" />
            </button>
            <p id={this.idSr} class="pn-select-sr-only" aria-live="assertive">
              {this.showSelection ? this.srOnlyText() : ''}
            </p>
            <div
              role="listbox"
              id={this.idOptions}
              class="pn-select-options"
              data-open={this.open}
              data-upwards={this.upwards}
              data-moving={this.moving}
              tabindex="-1"
            >
              {this.search && !this.disabled && (
                <pn-input
                  label={''}
                  type="search"
                  placeholder={this.searchLabel || this.translate('SEARCH')}
                  arialabel={this.searchLabel || this.translate('SEARCH')}
                  ariacontrols={this.selectId}
                  language={this.language}
                  inputid={this.searchId}
                  error={this.searchResult === 0 ? this.searchNoResults || this.translate('NO_SEARCH_RESULTS') : ''}
                  helpertext={this.searchHelper}
                  onInput={e => this.searchOptions(e)}
                  onKeyDown={e => this.handleSearchTab(e)}
                  onBlurCapture={() => this.handleSearchBlur()}
                />
              )}
              {this.emptyOption && !this.checkbox && (
                <pn-option label={this.translate('SELECT_AN_OPTION')} value={''} data-empty="true" />
              )}
              <slot />
            </div>
          </div>

          {this.hasMessage() && (
            <p id={this.idText} class="pn-select-description" role={!!this.error ? 'alert' : null}>
              <span>{this.error || this.helpertext}</span>
              {this.checkbox && (
                <span>
                  {this.boxesLength}/{this.elOptions?.length}
                </span>
              )}
            </p>
          )}
        </div>
      </Host>
    );
  }
}
