import { Component, Prop, h, Host } from '@stencil/core';

@Component({
  tag: 'pn-tag',
  styleUrl: 'pn-tag.scss',
})
export class PnTag {
  /** The label of the tag. */
  @Prop() label!: string;
  /** Select icon from out `pn-design-assets` list. Works with any SVG content. */
  @Prop() icon?: string;
  /** Set tag appearance: `discrete`, `positive`, `warning` & `error`. @category Visual */
  @Prop() appearance?: '' | 'discrete' | 'positive' | 'warning' | 'error' = '';
  /** Set darker background-color on the tag**.<br/> This is intended for better tag visibility on colored background. @category Visual */
  @Prop() dark: boolean = false;
  /** Set smaller tag. @category Size */
  @Prop() small: boolean = false;
  /** Set larger tag. @category Size */
  @Prop() large: boolean = false;

  render() {
    return (
      <Host>
        <div
          class="pn-tag"
          data-appearance={this.appearance}
          data-icon={!!this.icon}
          data-dark={this.dark}
          data-small={this.small}
          data-large={this.large}
        >
          {this.icon && <pn-icon icon={this.icon}></pn-icon>}
          <span class="pn-tag-label">{this.label}</span>
        </div>
      </Host>
    );
  }
}
