pn-modal .pn-modal {
  position: fixed;
  bottom: 50%;
  left: 50%;
  width: 95%;
  max-width: 45em;
  max-height: 85vh;
  border: 0.1rem solid $gray50;
  border-radius: 0.8rem;
  transform: translate(-50%, 60%);
  box-shadow:
    0px 4.8px 14.4px rgba(0, 0, 0, 0.18),
    0px 25.6px 57.6px rgba(0, 0, 0, 0.22);
  background: $white;
  z-index: 999;
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
  overflow-y: auto;
  transition:
    visibility 0s linear 0.55s,
    opacity 0.25s 0.15s,
    transform 0.55s $easing;
}

pn-modal[data-open] .pn-modal {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
  transition:
    visibility 0s linear 0s,
    opacity 0.55s,
    transform 0.55s $easing;
  transform: translate(-50%, 50%);
}

/*---------------------------------------Content slot-------------------------------------------*/
.pn-modal > .pn-modal-content {
  width: 100%;
  padding: 1.5em;
}

/*---------------------------------------/Content slot-------------------------------------------*/

/*---------------------------------------Close Button-------------------------------------------*/
.pn-modal > .pn-modal-close-button {
  position: absolute;
  right: 1em;
  top: 1em;
  outline: none;
  border: none;
  border-radius: 50%;
  padding: 0.3em;
  transition:
    box-shadow 0.15s,
    background 0.15s;
  cursor: pointer;
  background: $white;

  .pn-icon-svg path {
    transition: fill 0.15s;
    pointer-events: none;
  }

  &:hover,
  &:focus {
    background: $blue50;
  }

  &:focus {
    box-shadow:
      0 0 0 0.2rem $white,
      0 0 0 0.4rem $blue700;
  }

  &:active {
    background: $blue900;

    .pn-icon-svg path {
      fill: $white;
    }
  }
}
/*---------------------------------------/Close Button-------------------------------------------*/

/*---------------------------------------Button slot-------------------------------------------*/
pn-modal .pn-modal > [slot='buttons'] {
  position: static;
  width: 100%;
  background: $white;
  border-top: 0.1rem solid $gray200;
  padding: 0.5em 1em;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap-reverse;

  > * {
    margin: 0.5em;
  }
}
/*---------------------------------------/Button slot-------------------------------------------*/

/*---------------------------------------Backdrop-------------------------------------------*/
pn-modal > .pn-modal-backdrop {
  position: fixed;
  width: 120vw;
  height: 120vh;
  top: -10vh;
  left: -10vw;
  background: $gray900;
  opacity: 0;
  z-index: 9;
  visibility: hidden;
  transition:
    visibility 0s linear 0.3s,
    opacity 0.3s;
}

pn-modal[data-open] .pn-modal-backdrop {
  opacity: 0.65;
  visibility: visible;
  transition:
    visibility 0s linear 0s,
    opacity 0.3s;
}
/*---------------------------------------/Backdrop-------------------------------------------*/

/*---------------------------------------Mobile-------------------------------------------*/
@media screen and (max-width: 30em) {
  pn-modal .pn-modal {
    width: 100%;
    max-height: 95vh;
    bottom: 0;
    transform: translate(-50%, 10%);
    border-radius: 0.8rem 0.8rem 0 0;
  }

  pn-modal[data-open] .pn-modal {
    transform: translate(-50%, 0);
  }

  pn-modal .pn-modal > [slot='buttons'] {
    justify-content: center;

    > * {
      flex: 1 0 auto;
    }
  }
}
/*---------------------------------------/Mobile-------------------------------------------*/

/*---------------------------------------Modern browser styles-------------------------------------------*/
// I wanted to create fallbacks for IE, but realised old browsers don't support `@supports not (...)`, so I had to do it the messy way...
@supports (position: sticky) {
  pn-modal .pn-modal {
    & > [slot='buttons'] {
      position: sticky;
      bottom: 0;
      left: 0;
      z-index: 2;
    }

    & > .pn-modal-close-button {
      position: sticky;
      display: block;
      left: calc(100% - 3.1em);
      margin: 1em 1em 0 0;
      z-index: 10;
    }

    & > .pn-modal-content {
      margin-top: -3em;
    }
  }
}
/*---------------------------------------Modern browser styles-------------------------------------------*/
