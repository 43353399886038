$duration: 0.2s;
$content-delay: 0.2s;

pn-button {
  display: inline-block;
}

pn-button .pn-button {
  color: $white;
  cursor: pointer;
  padding: 0.75em 1.5em;
  width: 100%;
  border: none;
  border-radius: 3em;
  outline: none;
  position: relative;
  font-size: 1em;
  min-height: 3em;
  min-width: 5.5em;
  font-weight: 500;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: color 0.3s;
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  line-height: 1.5em;

  &-content {
    z-index: 2;
    transition:
      opacity 0.1s,
      transform $duration;
    transition-delay: $content-delay;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5em;
  }

  // This is a separate element because it has to be a leaf node to avoid bogging down performance when animating
  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%) translateY(0);
    border-radius: 3em;
    border: none;
    background-color: $blue700;
    transition:
      width $duration $easing $content-delay,
      background-color 0.1s,
      box-shadow 0.1s;
    overflow: hidden;

    // Hover element (sliding background)
    &::after {
      content: '';
      position: absolute;
      will-change: transform;
      transition: transform $duration $easing;
      width: 100%;
      height: 100%;
      background-color: $blue900;
      border-radius: inherit;
      left: 0;
      transform: translateX(-103%);
      opacity: 0.5;
    }
  }

  @include pn-ripple($white);

  &-tooltip {
    position: absolute;
    top: calc(100% + 0.35em);
    left: 50%;
    background-color: $blue700;
    color: $white;
    padding: 0.25em 0.75em;
    font-size: 0.875em;
    font-weight: 300;
    border-radius: 1em;
    box-shadow:
      0 0.075em 0.225em rgba(0, 0, 0, 0.1),
      0 0.4em 0.9em rgba(0, 0, 0, 0.13);
    z-index: 5;
    max-width: min(95vw, 40em);
    transition: opacity 0.15s $easing;
    transform: translateX(-50%) translateX(var(--pn-dx));
    width: max-content;
    opacity: 0;
    pointer-events: none;
  }

  pn-icon {
    flex-shrink: 0;
    .pn-icon-svg path {
      fill: $white;
      transition: fill 0.3s;
    }
  }

  pn-spinner {
    position: absolute;
    transform: scale(0);
  }

  &:focus {
    .pn-button-bg {
      box-shadow: $focus-ring-default;
    }
  }

  &:hover,
  &:focus {
    .pn-button-bg:after {
      transform: translateX(0);
    }
  }

  &[data-appearance='light'] {
    color: $blue700;

    pn-icon .pn-icon-svg path {
      fill: $blue700;
    }

    pn-spinner {
      .circle {
        stroke: $blue700;
      }
      .dot {
        fill: $blue700;
      }
    }

    .pn-button-bg {
      border-color: $blue700;
      background-color: $white;

      &:after {
        background-color: $blue50;
        opacity: 1;
      }

      .pn-ripple {
        background-color: $blue700;
      }
    }
  }

  &[data-appearance='warning'] {
    color: $warning;

    pn-icon .pn-icon-svg path {
      fill: $warning;
    }

    pn-spinner {
      .circle {
        stroke: $warning;
      }
      .dot {
        fill: $warning;
      }
    }

    .pn-button-bg {
      border: 0.0625em solid $warning;
      background-color: $coral50;

      &:after {
        background-color: $warning;
        opacity: 1;
      }
    }

    /* Tooltip */
    .pn-button-tooltip {
      background-color: $warning;
      color: $white;
    }

    &:hover,
    &:focus {
      color: $white;
      pn-icon .pn-icon-svg path {
        fill: $white;
      }

      pn-spinner {
        .circle {
          stroke: $white;
        }
        .dot {
          fill: $white;
        }
      }
    }

    &:focus {
      .pn-button-bg {
        box-shadow: $focus-ring-error;
      }
    }
  }

  &[data-variant='borderless'] {
    .pn-button-bg {
      border: none;
      background-color: transparent;
    }

    .pn-button-bg:after {
      background-color: $white;
      opacity: 0.1;
    }
    &[data-appearance='light'] {
      .pn-button-bg:after {
        background-color: $blue50;
        opacity: 1;
      }
    }
    &[data-appearance='warning'] {
      .pn-button-bg:after {
        background-color: $warning;
        opacity: 1;
      }
    }
  }

  &[data-variant='outlined'] {
    .pn-button-bg {
      border: 0.0625em solid $white;
      background-color: transparent;
      &:after {
        background-color: $white;
        opacity: 0.1;
      }
    }

    &[data-appearance='light'] .pn-button-bg {
      border-color: $blue700;
      background-color: $white;
      &:after {
        background-color: $blue400;
        opacity: 0.1;
      }
    }

    &[data-appearance='warning'] .pn-button-bg {
      border-color: $warning;
      &:after {
        background-color: $warning;
        opacity: 1;
      }
    }
  }

  &[data-left-icon] .pn-button-content {
    flex-direction: row-reverse;
  }

  &[data-icon-only] {
    width: 3em;
    min-width: 0;

    &[data-small] {
      width: 2em;
      min-width: 0;
      padding: 0;
    }
  }

  &[data-small] {
    padding: 0.375em 1em;
    min-height: 2em;
    min-width: none;
    line-height: 1.25em;

    .pn-button-content {
      gap: 0.25em;
    }

    .pn-button-text {
      font-size: 0.875em;
    }

    pn-icon .pn-icon-svg {
      height: 1.25em;
      width: 1.25em;
    }
  }

  &[data-tooltip-open] {
    .pn-button-tooltip {
      opacity: 1;
    }
  }

  &[data-tooltip-upwards] {
    .pn-button-tooltip {
      top: auto;
      bottom: calc(100% + 0.35em);
    }
  }

  &[data-loading] {
    pn-spinner {
      transform: scale(1);
      transition: transform $duration $easing 0.3s;
    }

    .pn-button-content {
      pointer-events: none;
      opacity: 0;
      transition-delay: 0s;
      transform: translateY(100%);
    }

    .pn-button-bg {
      width: 3em;
    }

    &[data-small] .pn-button-bg {
      width: 2em;
    }
  }
}
