pn-option {
  position: relative;
  display: block;
  &[hidden] {
    display: none;
  }
}

pn-option .pn-option {
  cursor: pointer;
  outline: 0;

  background-color: $white;
  padding: 0.75em;
  line-height: 1.5em;
  font-size: 1em;
  font-weight: 400;

  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.5em;
  overflow-wrap: break-word;

  transition-property: box-shadow, background-color;
  transition-duration: 0.2s;
  transition-timing-function: $easing;

  overflow: hidden;
  position: relative;

  @include pn-ripple($blue700);

  > span {
    transition: color 0.2s $easing;
    color: $gray900;
    &[data-checkbox] {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &-checkbox {
    background-color: $white;
    flex: 0 0 auto;
    width: 1.5em;
    height: 1.5em;
    margin-left: auto;
    border: 0.0625em solid $gray400;
    border-radius: 0.25em;
    transition-property: background-color, border-color;
    transition-timing-function: $easing;
    transition-duration: 0.2s;
    svg polyline {
      stroke: $white;
      stroke-linecap: round;
      stroke-dasharray: 23;
      stroke-dashoffset: 23;
      transition: stroke-dashoffset 0.2s $easing 0s;
    }
  }

  &[aria-selected='true'] {
    background-color: $blue50;
    .pn-option-checkbox {
      border-color: $blue700;
      background-color: $blue700;
      svg polyline {
        transition-delay: 0.2s;
        stroke-dashoffset: 0;
      }
    }
    &:hover,
    &[data-focus] {
      .pn-option-checkbox {
        border-color: $blue900;
        background-color: $blue900;
      }
    }
  }

  &[aria-disabled] {
    color: $gray700;
    background-color: $gray100;
    pointer-events: none;
    &:hover,
    &[data-focus] {
      color: $gray700;
      background-color: $gray50;
    }
    .pn-option-checkbox {
      background-color: $gray50;
    }
    &[aria-selected='true'] .pn-option-checkbox {
      border-color: $gray700;
      background-color: $gray700;
    }
  }

  &:hover,
  &[data-focus] {
    background-color: $blue25;
    .pn-option-checkbox {
      border-color: $blue700;
      background-color: $blue50;
    }
  }
}
