import { Component, h, Host, State, Element, Listen, forceUpdate } from '@stencil/core';
import type { AccordionArray } from '@/index';

@Component({
  tag: 'pn-accordion',
  styleUrl: 'pn-accordion.scss',
})
export class Pnaccordion {
  mo?: MutationObserver;

  @Element() hostElement: HTMLElement;

  @State() accordionRows: HTMLPnAccordionRowElement[] = Array.from(document.querySelectorAll('pn-accordion-row'));

  componentDidLoad() {
    if (this.mo) this.mo.disconnect();
    this.mo = new MutationObserver(() => forceUpdate(this.hostElement));

    this.mo.observe(this.hostElement, { childList: true });
  }

  @Listen('togglerow')
  toggleHandler({ detail }) {
    const arr = this.accordionRows.slice(this.accordionRows.indexOf(detail.element) + 1);
    this.animate([this.hostElement, ...arr]);
  }

  getRect(el: HTMLElement): DOMRect {
    return el.getBoundingClientRect();
  }

  animate(firstEls: [HTMLElement, ...HTMLPnAccordionRowElement[]]) {
    const getLastEls = () => firstEls;
    const firstElsRects: [HTMLElement, DOMRect][] = Array.from(firstEls).map(el => [el, this.getRect(el)]);

    requestAnimationFrame(() => {
      const lastElsRects: AccordionArray = Array.from(getLastEls()).map(el => [el, this.getRect(el)]);

      firstElsRects.forEach(([_firstEl, firstRect]: [HTMLElement, DOMRect], i) => {
        const [lastEl, lastRect] = lastElsRects[i];

        const dy = lastRect.y - firstRect.y;
        const dh = lastRect.height / firstRect.height;

        lastEl.dataset.flipping = 'true';
        lastEl.style.setProperty('--dy', `${dy}`);
        lastEl.style.setProperty('--dh', `${dh}`);

        requestAnimationFrame(() => delete lastEl.dataset.flipping);
      });
    });
  }

  render() {
    return (
      <Host>
        <div class="pn-accordion-bg"></div>
        <slot />
      </Host>
    );
  }
}
