import { Component, Element, Prop, h, Host, Watch } from '@stencil/core';
import { uuidv4 } from '@/index';

/**
 * The `pn-toggle-switch` is built with a native `input[type="checkbox"]` in the background.
 * This means you can use native events to listen to the changes.
 *
 * @nativeChange Use the `change` event to listen when the switch state is being toggled.
 */
@Component({
  tag: 'pn-toggle-switch',
  styleUrl: 'pn-toggle-switch.scss',
})
export class PnToggleSwitch {
  id: string = `pn-toggle-${uuidv4()}`;
  idHelper: string = `${this.id}-helper`;

  @Element() hostElement: HTMLElement;

  /** A label for the toggle switch. */
  @Prop() label!: string;
  /** Provide a helpertext for the toggle. */
  @Prop() helpertext?: string;
  /** Precheck the toggle. */
  @Prop({ mutable: true }) checked: boolean = false;
  /** Set a unique HTML id. */
  @Prop() toggleid: string = this.id;

  /** HTML toggle name. @category HTML */
  @Prop() name?: string = null;
  /** HTML toggle value. @category HTML */
  @Prop() value?: string = null;

  /** Make the toggle smaller. @category Visual */
  @Prop() small: boolean = false;
  /** Make the toggle larger. @category Visual */
  @Prop() large: boolean = false;

  /** Disable the toggle. @category State */
  @Prop() disabled: boolean = false;
  /** Set the loading state. @category State */
  @Prop() loading: boolean = false;

  @Watch('toggleid')
  handleHelperId() {
    if (this.id !== this.toggleid) this.idHelper = `${this.toggleid}-helper`;
  }

  componentWillLoad() {
    this.handleHelperId();
  }

  describedBy(): string {
    return !!this.helpertext ? this.idHelper : null;
  }

  render() {
    return (
      <Host>
        <div class="pn-toggle">
          {this.label && (
            <div class="pn-toggle-content">
              <label htmlFor={this.toggleid} class="pn-toggle-label">
                <span>{this.label}</span>
              </label>
              {!!this.helpertext && (
                <p id={this.idHelper} class="pn-toggle-helper">
                  <span>{this.helpertext}</span>
                </p>
              )}
            </div>
          )}
          <div class="pn-toggle-button" data-small={this.small} data-large={this.large} data-loading={this.loading}>
            <input
              id={this.toggleid}
              class="pn-toggle-element"
              type="checkbox"
              name={this.name}
              value={this.value}
              checked={this.checked}
              disabled={this.disabled}
              aria-describedby={this.describedBy()}
            />
            <div class="pn-toggle-bg"></div>
            <div class="pn-toggle-circle">
              <svg class="pn-toggle-check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                <polyline points="4,12 9,17 20,6" stroke-width="3" />
              </svg>
              <pn-spinner />
            </div>
          </div>
        </div>
      </Host>
    );
  }
}
