import { Component, Prop, h, Host } from '@stencil/core';
import * as colors from 'pn-design-assets/pn-assets/colors.js';

@Component({
  tag: 'pn-icon',
  styleUrl: 'pn-icon.scss',
})
export class PnIcon {
  /** The SVG content of the icon you want to use */
  @Prop() icon: string = '';
  /** Medium is default but you can pass "small" for a 16x16 icon */
  @Prop() small: boolean = false;
  /** Check for the available colors in the knobs panel in the story */
  @Prop() color: string = 'gray900';

  getIcon() {
    if (/pn-icon-flag/.test(this.icon)) return this.icon;
    // The replace is just setting the fill attribute on the SVG to the color passed to the component
    return this.icon.replace(/(black|#000)/g, colors[this.color]);
  }

  render() {
    return <Host role="presentation" class={`pn-icon ${this.small ? 'small' : ''}`} innerHTML={this.getIcon()}></Host>;
  }
}
