pn-accordion-row {
  position: relative;
  color: $black;
  display: flex;
  align-items: center;
  @include pn-transition('transform');

  & + & {
    border-top: 0.0625em solid $gray200;
    margin-top: 0.25em;
  }

  &[data-flipping] {
    transform: translateY(calc(-0.0625em * var(--dy)));
    transition: none;
  }
}

pn-accordion-row .pn-accordion-row {
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  &-title {
    display: flex;
    align-items: center;
    z-index: 0;
    width: 100%;
    font-weight: 400;
    padding: 1em;
    border-radius: 0.5em;
    overflow: hidden;
    position: relative;
    background-color: $white;
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 1em;
    -webkit-tap-highlight-color: transparent;

    @include pn-transition('background-color, box-shadow');

    &:hover {
      background-color: $blue50;
    }

    &:focus {
      box-shadow: $focus-ring-default;
    }

    &[aria-expanded='true'] {
      > .pn-accordion-row-icon {
        transform: rotate(-180deg);
        transition-delay: 0s;
      }
      ~ .pn-accordion-row-content {
        position: relative;
        pointer-events: unset;
        opacity: 1;
        visibility: visible;
        transition-delay: $timing;
      }
    }

    @include pn-ripple($blue700);
  }

  &-icon {
    margin: 0 0 auto auto;
    flex-shrink: 0;
    @include pn-transition('transform');
    transition-delay: $timing;
    min-height: 1em;

    path {
      fill: $blue700;
    }
  }

  &-content {
    position: absolute;
    left: 0;
    right: 0;
    width: 100%;
    top: 100%;
    display: flex;
    padding: 1em;
    flex-direction: column;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    @include pn-transition('opacity, visibility');
  }
}
