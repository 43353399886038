pn-input {
  display: inline-flex;
  flex-direction: column;
}

pn-input .pn-input {
  &-label {
    @include pn-input-label;
  }

  &-group {
    position: relative;
  }

  &-element {
    --pn-input-offset-left: 0em;
    --pn-input-offset-right: 0em;

    @include pn-input-field;

    width: 100%;

    padding-left: calc(var(--pn-input-offset-left) + 0.75em);
    padding-right: calc(var(--pn-input-offset-right) + 0.75em);

    &:read-only {
      @include pn-readonly;
    }

    &:disabled ~ .pn-input-eyecandy > pn-icon > .pn-icon-svg > path {
      fill: $input-button-disabled;
    }

    &::-webkit-calendar-picker-indicator {
      visibility: hidden;
      display: none;
    }

    &::-webkit-textfield-decoration-container,
    &::-webkit-search-cancel-button,
    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button,
    &::-webkit-search-decoration {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
    }

    &[type='number'] {
      appearance: textfield;
    }
  }

  &-eyecandy {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    gap: 0.5em;
    height: 1.5em;
    &[data-prefix] {
      left: 0.75em;
    }
    &[data-suffix] {
      right: 0.75em;
    }
  }

  &-text {
    color: $gray700;
    font-weight: 400;
    line-height: 1.5em;
  }

  &-helper {
    @include pn-input-helpertext;
  }

  &[data-error] {
    > .pn-input-label {
      color: $label-color-error;
    }

    > .pn-input-group > .pn-input-element {
      @include pn-input-field-error;
    }

    > .pn-input-helper[role='alert'] {
      color: $helpertext-color-error;
    }
  }

  &[data-valid] {
    > .pn-input-label {
      color: $label-color-success;
    }

    > .pn-input-group > .pn-input-element {
      @include pn-input-field-success;
    }
  }
}
