pn-tablist {
  width: 100%;
  min-width: 0;
  position: relative;
  border-bottom: 0.0625em solid $gray200;
  display: block;

  .pn-tablist,
  ol,
  ul {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 1em;
    position: relative;
    list-style: none;
    padding: 0;
    margin: 0;
    &[data-stacked] .pn-tab {
      margin: 0.5em 0.25em 0.75em;
      gap: 0.25em;
      flex-direction: column;
    }
    &[data-small] .pn-tab {
      margin: 0.25em 0.25em 0.75em;
      > *:not(pn-icon) {
        font-size: 0.875em;
      }
    }
    &[data-large] .pn-tab > *:not(pn-icon) {
      font-size: 1.25em;
    }
    &[data-scroll] {
      overflow-y: hidden;
      overflow-x: auto;
      scroll-snap-type: x mandatory;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }

  > .pn-scroll-arrows {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    pointer-events: none;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    height: 100%;
    > .pn-arrow {
      pointer-events: all;

      transition-property: transform, opacity, visibility;
      transition-duration: 0.2s;
      transition-timing-function: $easing;

      transform: scaleY(0);
      opacity: 0;
      visibility: hidden;

      &[data-show] {
        opacity: 1;
        visibility: visible;
        transform: scaleY(1);
      }
    }
  }

  .pn-line {
    height: 0.25em;
    position: absolute;
    bottom: 0;

    > &-item {
      position: absolute;
      transform-origin: left center;
      width: 100%;
      height: inherit;
      border-radius: 0.25em 0.25em 0 0;
      opacity: 0;
      transition-property: transform, width, opacity;
      transition-duration: 0.3s;
      transition-timing-function: $easing;
    }

    > &-active {
      z-index: 1;
      background-color: $blue700;
      width: var(--pn-active-width);
      transform: translateX(var(--pn-active-offset));
      opacity: var(--pn-active-opacity);
    }

    > &-hovered {
      background-color: $blue400;
      width: var(--pn-hover-width);
      transform: translateX(var(--pn-hover-offset));
      opacity: var(--pn-hover-opacity);
    }
  }
}
