pn-toggle-switch {
  display: inline-block;
  position: relative;
}

pn-toggle-switch .pn-toggle {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 1em;

  &-label {
    @include pn-input-label(500, 0, 1em);
  }

  &-helper {
    @include pn-input-helpertext;
  }

  &-content {
    flex: 1 0;
  }

  &-button {
    position: relative;
    font-size: 1em;
    width: 3.625em;
    height: 2em;
    padding: 0.1875em;

    @include pn-transition('transform');

    -webkit-tap-highlight-color: transparent;

    &[data-small] {
      width: 2.625em;
      height: 1.5em;
      .pn-toggle-circle {
        width: 1.125em;
        height: 1.125em;
      }
      &[data-loading] > .pn-toggle-bg {
        width: 1.5em;
      }
    }

    &[data-large] {
      width: 4.625em;
      height: 2.5em;
      .pn-toggle-circle {
        width: 2.125em;
        height: 2.125em;
      }
      &[data-loading] > .pn-toggle-bg {
        width: 2.5em;
      }
    }

    &[data-loading] {
      .pn-toggle-bg {
        width: 2em;
      }

      .pn-toggle-circle,
      .pn-toggle-element:checked ~ .pn-toggle-circle {
        transform: translateX(50%);
      }

      .pn-toggle-element:checked ~ .pn-toggle-circle .pn-toggle-check {
        opacity: 0;
        polyline {
          stroke-dashoffset: 23;
          transition-delay: 0s;
        }
      }

      pn-spinner {
        opacity: 1;
      }
    }
  }

  &-bg {
    position: absolute;
    top: 0;
    left: 50%;
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 2em;
    background-color: $gray400;
    transform: translateX(-50%);

    @include pn-transition('width, background-color, box-shadow');
  }

  &-circle {
    position: relative;
    z-index: 1;
    width: 1.625em;
    height: 1.625em;
    background-color: $white;
    box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    padding: 0.25em;
    @include pn-transition('transform, background-color');
    transform: translateX(0%);
  }

  &-element {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    cursor: pointer;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;

    /*---------------------------------------Focus-------------------------------------------*/

    &:focus,
    &:active {
      + .pn-toggle-bg {
        box-shadow: $focus-ring-default;
      }
    }

    &:hover + .pn-toggle-bg {
      background-color: $gray700;
    }

    &:hover:checked {
      + .pn-toggle-bg {
        background-color: $blue900;
      }
      ~ .pn-toggle-circle .pn-toggle-check polyline {
        stroke: $blue900;
      }
    }

    &:checked ~ {
      .pn-toggle-bg {
        background-color: $blue700;
      }

      .pn-toggle-circle {
        transform: translateX(100%);
        .pn-toggle-check {
          opacity: 1;
          polyline {
            transition-delay: $timing, 0s;
            stroke-dashoffset: 0;
          }
        }
      }
    }

    &:disabled {
      cursor: not-allowed;

      + .pn-toggle-bg {
        background-color: $gray200;
        box-shadow: none;
      }

      ~ .pn-toggle-circle {
        background-color: $gray50;
        .pn-toggle-check polyline {
          stroke: $gray400;
        }
        pn-spinner {
          .circle {
            stroke: $gray400;
          }
          .dot {
            fill: $gray400;
          }
        }
      }
    }

    &:disabled:hover,
    &:disabled:hover:checked {
      + .pn-toggle-bg {
        background-color: $gray200;
      }
      ~ .pn-toggle-circle .pn-toggle-check polyline {
        stroke: $gray400;
      }
    }
  }

  &-check {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 1.5em;
    width: 1.5em;
    padding: 0.25em;
    transform: translate(-50%, -50%);
    opacity: 0;
    @include pn-transition('opacity');
    polyline {
      stroke: $blue700;
      stroke-linecap: round;
      stroke-dasharray: 23;
      stroke-dashoffset: 23;

      @include pn-transition('stroke-dashoffset, stroke');
    }
  }

  pn-spinner {
    opacity: 0;
    width: 100%;
    height: 100%;
    @include pn-transition('opacity');
    .circle {
      @include pn-transition('stroke');
    }
    .dot {
      @include pn-transition('fill');
    }
  }
}
