import { uuidv4, ripple } from '@/globals/helpers';
import { Component, Prop, h, Host, Event, EventEmitter, Watch, Element } from '@stencil/core';

/** Listen to the event coming from the `pn-select`. These events are for the select and options to communicate, not for end users. */
@Component({
  tag: 'pn-option',
  styleUrl: 'pn-option.scss',
})
export class PnOption {
  option: HTMLDivElement;

  @Element() hostElement: HTMLElement;

  /** Option label */
  @Prop() label!: string;
  /** Value of the option */
  @Prop() value!: string;
  /** Set the SVG content of the icon */
  @Prop() icon?: string;
  /** Set the option HTML ID */
  @Prop() optionId: string = `pn-option-${uuidv4()}`;
  /** Turn this option into a checkbox */
  @Prop() checkbox: boolean = false;
  /**
   * Only works if you use the `checkbox` option.
   *
   * Use the `value` prop on both the `pn-select` and `pn-option` to preselect an option.
   */
  @Prop({ mutable: true, reflect: true }) selected: boolean = false;
  /** Disable the option. */
  @Prop() disabled: boolean = false;

  /** Used internally by `pn-select`, do not use it manually. @hide true */
  @Prop() parentValue?: string;
  /** Used internally by `pn-select`, do not use it manually. @hide true */
  @Prop() focusEl: boolean = false;

  /** This event is triggered when you select an option (used internally) */
  @Event() optionClick: EventEmitter<{ value: string; label: string; selected: boolean }>;
  /** This event is triggered when you focus an option (used internally) */
  @Event() optionFocus: EventEmitter<{ blur?: boolean; id?: string }>;

  @Watch('parentValue')
  watchParentValue() {
    if (this.checkbox) return;
    this.selected = this.value === this.parentValue;
  }

  componentDidRender() {
    if (this.focusEl) this.setFocus();
  }

  componentDidLoad() {
    this.option = this.hostElement.querySelector<HTMLDivElement>('.pn-option');
  }

  setFocus() {
    this.option.focus();
    this.optionFocus.emit({ id: this.optionId });
  }

  handleClick(e?: MouseEvent) {
    const isKeyboard = e.clientX === 0 && e.clientY === 0;
    const { x, width, y, top } = this.option.getBoundingClientRect();

    const clientCor = isKeyboard ? { clientX: x + width - 24, clientY: y - top } : e;

    this.checkbox && ripple(clientCor, this.hostElement, '.pn-option');
    this.optionClick.emit({ value: this.value, label: this.label, selected: !this.selected });
  }

  render() {
    return (
      <Host>
        <div
          id={this.optionId}
          class="pn-option"
          role="option"
          aria-selected={this.selected.toString()}
          aria-disabled={this.disabled ? 'true' : null}
          data-focus={this.focusEl}
          onClick={e => this.handleClick(e)}
        >
          {!!this.icon && <pn-icon icon={this.icon} color="blue900" />}
          <span data-checkbox={this.checkbox}>{this.label}</span>
          {this.checkbox && (
            <div class="pn-option-checkbox">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                <polyline points="4,12 9,17 20,6" stroke-width="3" />
              </svg>
            </div>
          )}
        </div>
      </Host>
    );
  }
}
