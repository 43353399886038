export const translations = {
  SELECT_AN_OPTION: {
    en: 'Select an option',
    sv: 'Välj ett alternativ',
    da: 'Vælg en mulighed',
    fi: 'Valitse vaihtoehto',
    no: 'Velg et alternativ',
  },
  SEARCH: {
    en: 'Search',
    sv: 'Sök',
    da: 'Søg',
    fi: 'Hae',
    no: 'Søk',
  },
  RESULTS_FOUND: {
    en: 'options found',
    sv: 'alternativ hittades',
    da: 'muligheder fundet',
    fi: 'vaihtoehtoja löytyi',
    no: 'alternativer funnet',
  },
  NO_SEARCH_RESULTS: {
    en: 'No options found',
    sv: 'Inga alternativ hittades',
    da: 'Ingen muligheder fundet',
    fi: 'Vaihtoehtoja ei löytynyt',
    no: 'Finner ingen alternativer',
  },
};
