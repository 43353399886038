import { Component, Element, Prop, h, Host } from '@stencil/core';

@Component({
  tag: 'pn-spinner',
  styleUrl: 'pn-spinner.scss',
})
export class PnSpinner {
  @Element() hostElement: HTMLElement;

  /** Light version of the spinner */
  @Prop() light: boolean = false;
  /** Size in em */
  @Prop() size: number = 1.5;

  render() {
    return (
      <Host class={this.light ? 'light' : ''} style={{ '--size': this.size + 'em' }}>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
          <circle class="circle" cx="12" cy="12" r="10" stroke-width="3" />
          <circle class="dot" cx="21.3" cy="16" r="1.48" />
        </svg>
      </Host>
    );
  }
}
