export default {
  LEFT: {
    sv: 'Bläddra åt vänster',
    en: 'Scroll to the left',
    da: 'Rul til venstre',
    fi: 'Vieritä vasemmalle',
    no: 'Rull til venstre',
  },
  RIGHT: {
    sv: 'Bläddra åt höger',
    en: 'Scroll to the right',
    da: 'Rul til højre',
    fi: 'Vieritä oikealle',
    no: 'Rull til høyre',
  },
};
