import { Element, Component, Prop, h, State, Watch } from '@stencil/core';
import { alert_info_circle, alert_question_circle } from 'pn-design-assets/pn-assets/icons.js';

@Component({
  tag: 'pn-tooltip',
  styleUrl: 'pn-tooltip.scss',
})
export class PnTooltip {
  content: HTMLDivElement;
  button: HTMLButtonElement;

  openEvents: string[] = ['mouseover', 'focus'];
  closeEvents: string[] = ['mouseleave', 'blur', 'touchcancel', 'touchleave'];
  eventListeners: string[] = [...this.openEvents, ...this.closeEvents];

  timeout: NodeJS.Timeout;

  @Element() hostElement: HTMLElement;

  @State() downwards: boolean = false;
  @State() open: boolean = false;
  @State() deltaX: string = '0';

  /** Apply the warning color and change the icon to `!`. */
  @Prop() warning: boolean = false;
  /** Apply the light color. */
  @Prop() light: boolean = false;

  @Watch('open')
  openHandler() {
    if (this.open) this.checkPosition();
    else this.setTransform();
  }

  componentDidLoad() {
    this.button = this.hostElement.querySelector<HTMLButtonElement>('.pn-tooltip');
    this.content = this.hostElement.querySelector<HTMLDivElement>('.pn-tooltip-content');

    this.initTooltip();
  }

  setTransform() {
    this.content.style.transform = `scale(${Number(this.open)}) translateX(-50%) translateX(${this.deltaX}px)`;
  }

  getSymbol(): string {
    return this.warning ? alert_info_circle : alert_question_circle;
  }

  checkPosition() {
    this.content.style.transition = 'none';
    this.content.style.transform = 'scale(1) translateX(-50%)';
    this.downwards = false;

    requestAnimationFrame(() => {
      const { x, y, right } = this.content.getBoundingClientRect();
      const extraMargin = 8;
      this.deltaX = '0';
      this.downwards = y < 0;
      this.content.style.transform = '';

      if (x < 0) this.deltaX = `${x * -1 + extraMargin}`;
      if (right > window.innerWidth) this.deltaX = `${window.innerWidth - right - extraMargin}`;

      requestAnimationFrame(() => {
        this.content.style.transition = '';
        this.setTransform();
      });
    });
  }

  initTooltip() {
    this.eventListeners.forEach(e => this.button.addEventListener(e, this.toggleTooltip));
  }

  toggleTooltip = (event: MouseEvent | FocusEvent) => {
    const status = this.openEvents.includes(event.type);

    let time = 500;

    if (status) {
      time = 0;
    }

    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      this.open = status;
    }, time);
  };

  render() {
    return (
      <button
        class="pn-tooltip"
        data-light={this.light}
        data-warning={this.warning}
        data-downwards={this.downwards}
        data-open={this.open}
      >
        <pn-icon icon={this.getSymbol()}></pn-icon>
        <div class="pn-tooltip-content">
          <slot />
        </div>
      </button>
    );
  }
}
