import {
  Component,
  Prop,
  Listen,
  Element,
  Event,
  EventEmitter,
  State,
  h,
  Host,
  Watch,
  forceUpdate,
} from '@stencil/core';
import { angle_left, angle_right } from 'pn-design-assets/pn-assets/icons.js';
import translations from './translations';
import { awaitTopbar, en } from '@/globals/helpers';
import type { PnLanguages } from '@/globals/types';

/**
 * @slot menu - Can only be used inside of a `pn-header`. Allows the `href` attribute on the `pn-tab` when active.
 */
@Component({
  tag: 'pn-tablist',
  styleUrl: 'pn-tablist.scss',
})
export class PnTablist {
  mo: MutationObserver;

  isMenu: boolean = false;
  isTabHovered: boolean = false;

  tabListEl: HTMLDivElement;
  lineActive: HTMLDivElement;
  lineHovered: HTMLDivElement;
  tabElement: HTMLPnTabElement;

  @Element() hostElement: HTMLElement;

  /** Display the scrolling arrows */
  @State() showScrollArrows: boolean = false;
  /** Display the scroll arrow to the left */
  @State() arrowLeft: boolean = false;
  /** Display the scroll arrow to the right */
  @State() arrowRight: boolean = false;

  /** Give the tablist a name for screenreaders */
  @Prop() label!: string;
  /** The value of the tab that is currently active, each `<pn-tab value="example-value">` also expects a unique value */
  @Prop({ reflect: true, mutable: true }) value!: string;
  /** Make the tablist smaller */
  @Prop() small?: boolean;
  /** Icons are stacked vertically instead of the default rows */
  @Prop() stackedicons: boolean = false;
  /** Manually set the language. */
  @Prop() language?: PnLanguages = null;

  /**
   * This will emit when a tab is changed. The detail property of the event will contain the value of the selected tab.
   * This is the event and value you listen to when you toggle the visibility among your tabpanels.
   **/
  @Event() tabchange: EventEmitter<string>;

  @Watch('value')
  setValue() {
    const tabs = Array.from(this.hostElement.querySelectorAll('pn-tab'));

    tabs.forEach(tab => {
      tab.activeTab = this.value;
    });
  }

  @Watch('showScrollArrows')
  scrollHandler() {
    if (this.showScrollArrows) {
      this.tabListEl.addEventListener('scroll', this.scrollIndicators.bind(this));
    } else {
      this.tabListEl.removeEventListener('scroll', this.scrollIndicators);
    }
  }

  @Listen('setActiveTab')
  setActiveTabHandler({ detail }) {
    this.tabElement = detail.el;
    requestAnimationFrame(() => this.activateTab(detail.el));
    if (this.value === detail.val) return;
    this.value = detail.val;
    this.tabchange.emit(this.value);
    this.tabElement.querySelector(this.isMenu ? 'a' : 'button').focus();
  }

  @Listen('resize', { target: 'window' })
  rerender() {
    requestAnimationFrame(() => {
      this.scrollIndicators();
      this.isTabHovered = false;
    });
  }

  @Listen('tabEnter')
  handleEnter(e: { target: HTMLPnTabElement }) {
    this.isTabHovered = true;
    this.styleLines(e.target);
  }

  @Listen('tabLeave')
  handleLeave() {
    this.isTabHovered = false;
    setTimeout(() => {
      if (!this.isTabHovered) this.lineHovered.style.setProperty('--pn-hover-opacity', '0');
    }, 500);
  }

  async componentWillLoad() {
    this.isMenu = this.hostElement.slot === 'menu';
    if (this.language) return;
    await awaitTopbar(this.hostElement);
  }

  componentDidLoad() {
    this.tabListEl = this.hostElement.querySelector('.pn-tablist');
    this.lineActive = this.hostElement.querySelector('.pn-line-active');
    this.lineHovered = this.hostElement.querySelector('.pn-line-hovered');

    if (this.mo) this.mo.disconnect();
    this.mo = new MutationObserver(() => {
      forceUpdate(this.hostElement);
      this.rerender();
    });

    this.mo.observe(this.hostElement, { childList: true, subtree: true });
  }

  componentDidRender() {
    this.rerender();
    this.setValue();
  }

  getRect(element: HTMLElement | HTMLPnTablistElement): DOMRect {
    return element.getBoundingClientRect();
  }

  activateTab(element: HTMLPnTabElement) {
    this.styleLines(element, true);
  }

  styleLines(element: HTMLElement, active: boolean = false) {
    const tabListCoords = this.getRect(this.tabListEl);
    const scrollOffset = this.tabListEl.scrollLeft;

    const line = this.getRect(element);

    const width = line.width / 16;
    const offset = (line.left + scrollOffset - tabListCoords.left) / 16;

    const cssVar = active ? 'active' : 'hover';
    const prop = active ? 'lineActive' : 'lineHovered';

    this[prop].style.setProperty(`--pn-${cssVar}-width`, `${width}em`);
    this[prop].style.setProperty(`--pn-${cssVar}-offset`, `${offset}em`);
    this[prop].style.setProperty(`--pn-${cssVar}-opacity`, '1');
  }

  scrollIndicators() {
    this.tabListEl = this.hostElement.querySelector('.pn-tablist');

    const { scrollWidth, scrollLeft } = this.tabListEl;

    const { clientWidth } = this.hostElement;

    this.showScrollArrows = scrollWidth > clientWidth;

    this.arrowLeft = this.showScrollArrows && scrollLeft > 0;
    this.arrowRight = this.showScrollArrows && clientWidth + 16 + scrollLeft < scrollWidth;
  }

  scroll({ right = false }: { right?: boolean } = {}) {
    const tabList = this.tabListEl;
    const { scrollLeft, clientWidth } = tabList;

    let left: number = scrollLeft;
    // The width of the scroll arrow is 32px, so we remove that from this calculation so the scroll
    const scrollAmount = clientWidth - 64;

    if (right) left += scrollAmount;
    else left -= scrollAmount;

    tabList.scrollTo({
      left,
      behavior: 'smooth',
    });
  }

  translate(prop: string): string {
    return translations?.[prop]?.[this.language || en];
  }

  render() {
    return (
      <Host>
        <nav
          class="pn-tablist"
          role={this.isMenu ? null : 'tablist'}
          aria-label={this.label}
          data-stacked={this.stackedicons}
          data-small={!this.isMenu && this.small}
          data-large={this.isMenu}
          data-scroll={this.showScrollArrows}
        >
          <slot />
          <div class="pn-line">
            <div class="pn-line-item pn-line-active" />
            <div class="pn-line-item pn-line-hovered" />
          </div>
        </nav>

        <div class="pn-scroll-arrows">
          <pn-button
            class="pn-arrow"
            data-show={this.arrowLeft}
            onClick={() => this.scroll()}
            noTab={true}
            appearance="light"
            variant="outlined"
            icon={angle_left}
            tooltip={this.translate('LEFT')}
            small
          ></pn-button>

          <pn-button
            class="pn-arrow"
            data-show={this.arrowRight}
            onClick={() => this.scroll({ right: true })}
            noTab={true}
            appearance="light"
            variant="outlined"
            icon={angle_right}
            tooltip={this.translate('RIGHT')}
            small
          ></pn-button>
        </div>
      </Host>
    );
  }
}
