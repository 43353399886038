import { Component, Prop, Event, EventEmitter, Element, h, Host, State } from '@stencil/core';
import { ripple } from '@/globals/helpers';

@Component({
  tag: 'pn-tab',
  styleUrl: 'pn-tab.scss',
})
export class PnTab {
  tag: string = 'button';

  @Element() hostElement: HTMLElement;

  /** We only want to show the focus ring when the user navigates with keyboard. */
  @State() displayFocus: boolean = false;
  @State() pressAndHold: boolean = false;

  /** Set a label for the tab. */
  @Prop() label!: string;
  /** This is the value that will be matched with `pn-tablist` value. (required) */
  @Prop() value!: string;
  /** Turns the tab from a `button` to an `a` element, but only if the `pn-tablist` is inside a `pn-header`. */
  @Prop() href?: string;
  /** The SVG content of the icon you want to display */
  @Prop() icon?: string;
  /** Use the ID of the container that this tab controls.  */
  @Prop() ariacontrols?: string;
  /** Tab ID, use if you want to have the tab container be `aria-labelledby` by this tab. */
  @Prop() tabid?: string;
  /** Is set by `pn-tablist`, don't use this prop. @hide true */
  @Prop() activeTab: string;

  /** Used by `pn-tab` to communicate with `pn-tablist`. Emits the selected tab value and element. */
  @Event() setActiveTab: EventEmitter<{ val: string; el: HTMLPnTabElement }>;
  setActiveTabHandler({ click = false, element }: { click?: boolean; element?: HTMLPnTabElement } = {}) {
    if (click || this.isActive()) {
      const val = element?.value || this.value;
      const el = (element?.value && element) || (this.hostElement as HTMLPnTabElement);

      this.setActiveTab.emit({
        val,
        el,
      });
    }
  }

  /** Used by `pn-tab` to communicate with `pn-tablist`. Emits when the tab gets focus. */
  @Event() tabEnter: EventEmitter<MouseEvent | FocusEvent>;
  triggerEnter(event: MouseEvent | FocusEvent) {
    if (!this.displayFocus) this.handleVisibleFocus(true);
    this.tabEnter.emit(event);
  }

  /** Used by `pn-tab` to communicate with `pn-tablist`. Emits when the tab is blured.  */
  @Event() tabLeave: EventEmitter<MouseEvent | FocusEvent>;
  triggerLeave(event: MouseEvent | FocusEvent) {
    this.tabLeave.emit(event);
  }

  componentWillLoad() {
    this.tabTag();
  }

  componentDidUpdate() {
    this.setActiveTabHandler();
  }

  componentDidLoad() {
    this.setActiveTabHandler();
  }

  arrowKeyNav(event: KeyboardEvent) {
    if (!/^(ArrowRight|ArrowLeft|Home|End)$/.test(event.key)) return;

    event.preventDefault();

    this.handleVisibleFocus(true);
    this.longClick(false);

    // Get tablist parent
    const parent = (event.target as HTMLButtonElement | HTMLLinkElement).closest<HTMLPnTablistElement>('pn-tablist');
    const list = Array.from(parent.querySelectorAll('pn-tab'));

    const first = list[0];
    const last = list[list.length - 1];

    const nextElement = this.hostElement.nextElementSibling as HTMLPnTabElement;
    const previousElement = this.hostElement.previousElementSibling as HTMLPnTabElement;

    if (event.key === 'Home') {
      this.setActiveTabHandler({ element: first });
    }
    if (event.key === 'End') {
      this.setActiveTabHandler({ element: last });
    }
    if (event.key === 'ArrowRight') {
      // Check the next element value. We do this because the last element i a DIV element. If no value, go to the first element.
      this.setActiveTabHandler({ element: nextElement?.value ? nextElement : first });
    }
    if (event.key === 'ArrowLeft') {
      // Go to the last element if there is no element to your left.
      this.setActiveTabHandler({ element: previousElement?.value ? previousElement : last });
    }
  }

  isActive() {
    return this.activeTab === this.value;
  }

  handleVisibleFocus(state: boolean) {
    this.displayFocus = state;
  }

  tabTag() {
    const isMenu = this.hostElement.closest<HTMLPnTablistElement>('pn-tablist');
    this.tag = isMenu.slot === 'menu' ? 'a' : 'button';
  }

  renderProperties() {
    return this.tag === 'a'
      ? {
          'href': this.href,
          'aria-current': this.isActive() ? 'page' : 'false',
        }
      : {
          'tabindex': this.isActive() ? 0 : -1,
          'type': 'button',
          'role': 'tab',
          'aria-selected': this.isActive().toString(),
          'aria-controls': this.ariacontrols,
        };
  }

  handleClick(e: MouseEvent) {
    this.handleVisibleFocus(e.screenX === 0 && e.screenY === 0);
    this.setActiveTabHandler({ click: true });
    ripple(e, this.hostElement, '.pn-tab');
    this.longClick(false);
  }

  longClick(state: boolean) {
    this.pressAndHold = state;
  }

  render() {
    const Tag = this.tag;
    return (
      <Host>
        <Tag
          id={this.tabid}
          class="pn-tab"
          data-focus={this.pressAndHold ? false : this.displayFocus}
          {...this.renderProperties()}
          onClick={(e: MouseEvent) => this.handleClick(e)}
          onMouseDown={() => this.longClick(true)}
          onMouseEnter={(e: MouseEvent) => this.triggerEnter(e)}
          onFocus={(e: FocusEvent) => this.triggerEnter(e)}
          onMouseLeave={(e: MouseEvent) => this.triggerLeave(e)}
          onBlur={(e: FocusEvent) => this.triggerLeave(e)}
          onKeyDown={(e: KeyboardEvent) => this.arrowKeyNav(e)}
        >
          <slot></slot>
          {!!this.icon && <pn-icon icon={this.icon} />}
          <span>{this.label}</span>
        </Tag>
      </Host>
    );
  }
}
