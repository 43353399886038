import { Component, Element, h, Host, Prop, State, Watch, Event, EventEmitter, forceUpdate } from '@stencil/core';
import { close } from 'pn-design-assets/pn-assets/icons.js';

/**
 * @slot buttons - Place buttons inside the modal at the bottom. 
 */
@Component({
  tag: 'pn-modal',
  styleUrl: 'pn-modal.scss',
})
export class PnModal {
  mo: MutationObserver;

  untabbable: HTMLElement[];
  elToFocus: HTMLElement;

  handleFocus = this.focusHandler.bind(this);
  handleBlur = this.blurHandler.bind(this);
  handleEsc = this.escHandler.bind(this);
  handleGlobalClick = this.globalClickHandler.bind(this);

  @Element() hostElement: HTMLElement;

  @State() focusableEls: HTMLElement[];

  /** Bind to this property if you want to control the visibility of the modal from your own data. */
  @Prop({ mutable: true }) open: boolean = false;

  /** Event fired when the modal is closed, either by clicking outside or by triggering close button. */
  @Event() close: EventEmitter<boolean>;

  componentDidLoad() {
    if (this.mo) this.mo.disconnect();
    this.mo = new MutationObserver(() => {
      forceUpdate(this.hostElement);
      this.setFocusableElements();
    });

    this.mo.observe(this.hostElement.querySelector('.pn-modal'), { childList: true, subtree: true });

    // If the modal is opened when the page loads we still want to register the events.
    if (this.open) this.addEventListeners();

    this.setFocusableElements();
  }

  @Watch('open')
  openHandler() {
    if (this.open) {
      this.addEventListeners();
    } else {
      this.removeEventListeners();
      this.elToFocus = null;
      this.close.emit(this.open);
    }
  }

  setFocusableElements() {
    // This place is where I see the most coming changes/bugs taking place.
    requestAnimationFrame(() => {
      this.focusableEls = Array.from(
        this.hostElement.querySelectorAll(
          'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"]):not(.pn-modal-backdrop), pn-option',
        ),
      );

      this.untabbable = Array.from(this.hostElement.querySelectorAll('[tabindex="-1"]'));
    });
  }

  addEventListeners() {
    const root: Document = this.hostElement.getRootNode() as Document;

    root.addEventListener('focusin', this.handleFocus);
    root.addEventListener('focusout', this.handleBlur);
    root.addEventListener('keydown', this.handleEsc);

    // Adding RAF to ensure clicks aren't registered before the modal has opened.
    requestAnimationFrame(() => {
      document.addEventListener('pointerdown', this.handleGlobalClick);
    });
  }

  removeEventListeners() {
    const root: Document = this.hostElement.getRootNode() as Document;

    root.removeEventListener('focusin', this.handleFocus);
    root.removeEventListener('focusout', this.handleBlur);
    root.removeEventListener('keydown', this.handleEsc);
    document.removeEventListener('pointerdown', this.handleGlobalClick);
  }

  focusHandler(e: FocusEvent) {
    const target: HTMLElement = e.composedPath()[0] as HTMLElement;

    if (
      (!this.focusableEls.includes(target) && !this.untabbable.includes(target)) ||
      target.classList.contains('pn-modal-backdrop')
    ) {
      if (this.elToFocus) {
        this.elToFocus.focus();
        return;
      }

      this.focusableEls[0].focus();
    }
  }

  blurHandler(e: FocusEvent) {
    const target: HTMLElement = e.composedPath?.()[0] as HTMLElement;
    const index = this.focusableEls.indexOf(target);
    const numberOfEls = this.focusableEls.length - 1;

    if (index === 0) this.elToFocus = this.focusableEls[numberOfEls];
    if (index === numberOfEls) this.elToFocus = this.focusableEls[0];
  }

  escHandler({ code }) {
    if (code === 'Escape') this.open = false;
  }

  globalClickHandler(e: PointerEvent) {
    const target: HTMLElement = e.composedPath?.()[0] as HTMLElement;

    if (!this.hostElement.contains(target) || target.classList.contains('pn-modal-backdrop')) {
      // This is to prevent the focus and blur events from being triggered when closing the modal.
      e.preventDefault();
      this.open = false;
    }
  }

  render() {
    return (
      <Host data-open={this.open}>
        <div class="pn-modal-backdrop" tabindex="0"></div>
        <div class="pn-modal">
          <button
            class="pn-modal-close-button"
            onClick={() => (this.open = false)}
            type="button"
            title="close"
            aria-label="close"
          >
            <pn-icon icon={close} color="blue700"></pn-icon>
          </button>

          <div class="pn-modal-content">
            <slot></slot>
          </div>

          <slot name="buttons"></slot>
        </div>

        <div class="pn-modal-backdrop" tabindex="0"></div>
      </Host>
    );
  }
}
