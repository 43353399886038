import { uuidv4, awaitTopbar, en } from '@/globals/helpers';
import { Component, Prop, h, Host, Event, EventEmitter, Element, Listen } from '@stencil/core';
import { close_small, search } from 'pn-design-assets/pn-assets/icons.js';
import type { PnLanguages } from '@/globals/types';
import { translations } from './translations';

@Component({
  tag: 'pn-search-field',
  styleUrl: 'pn-search-field.scss',
})
export class PnSearchField {
  @Element() hostElement: HTMLElement;

  /** Provide an aria-label for the search field. */
  @Prop() label!: string;
  /** Set the value of the search field. */
  @Prop() value: string = '';
  /** Set a search field placeholder. */
  @Prop() placeholder?: string;
  /** Set a unique ID for the search input */
  @Prop() searchid: string = `pn-search-field-${uuidv4()}`;
  /** Set HTML name of the search input. */
  @Prop() name?: string;
  /** Allow the browser to autocomplete the search field. */
  @Prop() autocomplete?: string;
  /** Point to a datalist element with this id. */
  @Prop() list?: string;
  /** Override the pntopbar language. */
  @Prop() language?: PnLanguages = null;

  /** Disable the search field. @category State */
  @Prop() disabled: boolean = false;
  /** Set the search field as required. @category State */
  @Prop() required: boolean = false;
  /** Display loading animation. @category State */
  @Prop() loading: boolean = false;

  /** Button type, options are `none` for no button, `icon` for a labelless button with just a search icon and `icon-inline` for a search button inside of the search field (this last option disables the clear field button). @category Button */
  @Prop() button: '' | 'none' | 'icon' | 'icon-inline' = '';
  /** Label for the button element. @category Button */
  @Prop() buttonLabel?: string;
  /** Light instead of dark search button. @category Button */
  @Prop() buttonLight: boolean = false;

  /** This is emitted on search submission both with keyboard and mouse. */
  @Event() search: EventEmitter<string>;
  /** Custom event that handles both clearing and input to have the option of just binding listeners to one event instead of two. */
  @Event() update: EventEmitter<string>;

  @Listen('input')
  inputHandler() {
    this.update.emit(this.value);
  }

  async componentWillLoad() {
    if (this.language) return;
    await awaitTopbar(this.hostElement);
  }

  translate(prop: string) {
    return translations?.[prop]?.[this.language || en];
  }

  emitSearch({ click, button }: { click?: MouseEvent; button?: KeyboardEvent }) {
    if (click?.type === 'click' || (button?.type === 'keydown' && button?.key === 'Enter')) {
      // We prevent the native search event since it's not supported in IE and FF, then we emit our own instead
      const event = click || button;
      event.preventDefault();
      this.search.emit(this.value);
    }
  }

  setVal(e: InputEvent) {
    this.value = (e.target as HTMLInputElement).value;
  }

  clearInput() {
    this.value = '';
    this.update.emit(this.value);
    this.hostElement.querySelector('input').focus();
  }

  getClassNames() {
    let classNames = '';

    const buttonTypes = ['none', 'icon', 'icon-inline'];
    if (this.value) classNames += 'searching ';
    if (this.loading) classNames += 'loading ';
    if (buttonTypes.includes(this.button)) classNames += `button-${this.button} `;

    return classNames;
  }

  render() {
    return (
      <Host class={this.getClassNames()}>
        <div class="input-container">
          <input
            type="search"
            value={this.value}
            id={this.searchid}
            name={this.name}
            placeholder={this.placeholder}
            disabled={this.disabled}
            required={this.required}
            autocomplete={this.autocomplete}
            list={this.list}
            onKeyDown={e => this.emitSearch({ button: e })}
            onInput={e => this.setVal(e)}
            aria-label={this.label}
          />

          <div class="button-container">
            {(this.button === 'none' || this.button === 'icon-inline') && (
              <button
                class="search"
                tabindex={this.button === 'icon-inline' ? '0' : '-1'}
                onClick={e => this.emitSearch({ click: e })}
                aria-label={this.translate('SEARCH')}
                type="button"
              >
                <pn-icon icon={search} color="blue700"></pn-icon>
              </button>
            )}
            {this.button !== 'icon-inline' && (
              <button
                class="clear"
                aria-label={this.translate('CLEAR')}
                tabindex={this.value && (!this.loading || this.button !== 'none') ? '0' : '-1'}
                onClick={() => this.clearInput()}
                type="button"
              >
                <pn-icon icon={close_small} color="blue700"></pn-icon>
              </button>
            )}

            {this.button === 'none' || (this.button === 'icon-inline' && <pn-spinner></pn-spinner>)}
          </div>
        </div>

        {this.button !== 'none' && this.button !== 'icon-inline' && (
          <pn-button
            onClick={e => this.emitSearch({ click: e })}
            loading={this.loading}
            icon={search}
            appearance={this.buttonLight ? 'light' : null}
            icon-only={this.button === 'icon'}
            tooltip={this.button === 'icon' ? this.buttonLabel : null}
          >
            {this.buttonLabel}
          </pn-button>
        )}
      </Host>
    );
  }
}
