pn-toast {
  display: inline-block;
}
pn-toast .pn-toast {
  color: $gray900;
  padding: 1em;
  border: 0.0625em solid $blue700;
  border-radius: 0.5em;
  outline: none;
  font-size: 1em;
  font-weight: 400;
  background: $blue50;
  display: inline-flex;
  align-items: flex-start;
  gap: 0.5em;
  opacity: 1;
  visibility: visible;
  transition:
    visibility 0s linear 0s,
    opacity 0.35s,
    box-shadow 0.2s;
  z-index: 10;

  &[data-hide] {
    opacity: 0;
    visibility: hidden;
    transition:
      visibility 0s linear 0.35s,
      opacity 0.35s,
      box-shadow 0.2s;
  }

  pn-icon .pn-icon-svg path {
    fill: $blue700;
    transition: fill 0.3s;
  }

  &-content {
    align-self: center;
  }

  &-button {
    cursor: pointer;
    border-radius: 50%;
    border: none;
    background: none;
    padding: 0.25em;
    font-size: 1em;
    margin-left: auto;
    outline: none;
    transition:
      background-color 0.2s,
      box-shadow 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $blue200;
    }

    &:focus {
      background-color: $blue200;
      box-shadow: $focus-ring-default;
    }
  }

  &[data-appearance='warning'] {
    border-color: $warning;
    background-color: $coral50;

    .pn-toast-button {
      &:hover,
      &:focus {
        background-color: $coral200;
      }
      &:focus {
        box-shadow: $focus-ring-error;
      }
    }

    pn-icon .pn-icon-svg path {
      fill: $warning;
    }
  }

  &[data-appearance='success'] {
    border-color: $green700;
    background-color: $green50;

    .pn-toast-button {
      &:hover,
      &:focus {
        background-color: $green200;
      }
      &:focus {
        box-shadow: $focus-ring-success;
      }
    }

    pn-icon .pn-icon-svg path {
      fill: $green700;
    }
  }

  &[data-temporary] {
    box-shadow:
      0em 0.4em 0.875em rgba(0, 0, 0, 0.13),
      0em 0.075em 0.225em rgba(0, 0, 0, 0.1);
  }
}
