export const translations = {
  SEARCH: {
    en: 'Search',
    sv: 'Sök',
    da: 'Søg',
    fi: 'Hae',
    no: 'Søk',
  },
  CLEAR: {
    en: 'Clear search field',
    sv: 'Rensa sökfältet',
    da: 'Ryd søgefeltet',
    fi: 'Tyhjennä hakukenttä',
    no: 'Tøm søkefeltet',
  },
};
