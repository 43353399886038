pn-tag {
  display: inline-block;
}

pn-tag .pn-tag {
  display: inline-flex;
  flex-direction: row;
  gap: 0.5em;
  justify-content: center;
  align-items: center;

  border-radius: 0.5em;

  /* Default size - Medium */
  padding: 0.5em 1em;
  &[data-icon] {
    padding-left: 0.75em;
  }

  /* Default appearance */
  background-color: $blue50;
  color: $blue900;
  &[data-dark] {
    background-color: $blue200;
  }

  pn-icon .pn-icon-svg path {
    fill: $blue900;
  }

  &-label {
    line-height: 1.5em;
    font-weight: 500;
  }

  /* --------------------------------------- SIZES S + L --------------------------------------- */
  /** Small tag */
  &[data-small] {
    padding: 0.25em 0.75em;
    .pn-tag-label {
      font-size: 0.875em;
      line-height: 1.715em;
    }
    &[data-icon] {
      padding-left: 0.5em;
    }
  }
  /** Large tag */
  &[data-large] {
    padding: 0.75em 1.25em;
  }
  /* --------------------------------------- /SIZES S + L --------------------------------------- */

  /* --------------------------------------- APPEARANCE --------------------------------------- */
  &[data-appearance='discrete'] {
    background-color: $gray50;
    color: $gray900;
    &[data-dark] {
      background-color: $gray200;
    }

    pn-icon .pn-icon-svg path {
      fill: $gray900;
    }
  }

  &[data-appearance='positive'] {
    background-color: $green50;
    color: $green900;
    &[data-dark] {
      background-color: $green200;
    }

    pn-icon .pn-icon-svg path {
      fill: $green900;
    }
  }

  &[data-appearance='warning'] {
    background-color: $orange50;
    color: $orange700;
    &[data-dark] {
      background-color: $orange200;
    }

    pn-icon .pn-icon-svg path {
      fill: $orange700;
    }
  }

  &[data-appearance='error'] {
    background-color: $coral50;
    color: $warning;
    &[data-dark] {
      background-color: $coral200;
    }

    pn-icon .pn-icon-svg path {
      fill: $warning;
    }
  }
  /* --------------------------------------- /APPEARANCE --------------------------------------- */
}
