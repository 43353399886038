import { Component, h, Host, Element, Prop, Watch } from '@stencil/core';
import { uuidv4 } from '@/globals/helpers';

/**
 * The `pn-radio-button` is built with a native `input[type="radio"]` in the background.
 * This means you can use native events to listen to the changes.
 *
 * @nativeChange Use the `change` event to listen when the radio state is being changed.
 */
@Component({
  tag: 'pn-radio-button',
  styleUrl: 'pn-radio-button.scss',
})
export class PnRadioButton {
  id: string = `pn-radio-button-${uuidv4()}`;
  idHelper: string = `${this.id}-helpertext`;

  @Element() hostElement: HTMLElement;

  /** The radio button label */
  @Prop() label?: string = '';
  /** This will be emitted on `change` and `input`, like a native radio button (required)*/
  @Prop({ reflect: true }) value!: string;
  /** The name of the radio button group that will hold this button, like a native radio button (required) */
  @Prop() name: string;
  /** Check the radio button */
  @Prop() checked: boolean = false;
  /** With this prop you can add an optional helper text element to radio button. */
  @Prop() helpertext?: string;
  /** Give the radio button a custom ID */
  @Prop() radioid: string = this.id;

  /** Disable the radio button. @category State */
  @Prop() disabled?: boolean = false;
  /** Set the radio button as required. @category State */
  @Prop() required?: boolean = false;
  /** Trigger the red error state. @category State */
  @Prop() invalid?: boolean = false;

  /** Turn this radio-button into a radio-tile. @category Tile */
  @Prop() tile?: boolean = false;
  /** Add an icon on the right of your radio tile. The `tile` prop must be `true` for the icon to work. @category Tile */
  @Prop() icon?: string = null;

  @Watch('radioid')
  handleId() {
    this.idHelper = `${this.radioid}-helpertext`;
  }

  componentWillLoad() {
    this.handleId();
  }

  render() {
    return (
      <Host>
        <input
          type="radio"
          id={this.radioid}
          value={this.value}
          name={this.name}
          disabled={this.disabled}
          required={this.required}
          checked={this.checked}
          aria-describedby={!!this.helpertext && this.idHelper}
        />
        <div class="pn-radio" data-tile={this.tile} data-invalid={this.invalid}>
          <div class="pn-radio-container">
            <div class="pn-radio-outer">
              <div class="pn-radio-inner" />
            </div>
          </div>
          {(this.label || this.helpertext) && (
            <p class="pn-radio-content">
              {this.label && (
                <label htmlFor={this.radioid} class="pn-radio-label">
                  {this.label}
                </label>
              )}
              {this.helpertext && (
                <span id={this.idHelper} class="pn-radio-helpertext">
                  {this.helpertext}
                </span>
              )}
            </p>
          )}
          {this.tile && this.icon && <pn-icon color="gray900" icon={this.icon} />}
        </div>
      </Host>
    );
  }
}
