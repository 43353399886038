import { PnTopbar } from './types';

export const SE = 'SE';
export const DK = 'DK';
export const FI = 'FI';
export const NO = 'NO';

export const sv = 'sv';
export const en = 'en';
export const da = 'da';
export const fi = 'fi';
export const no = 'no';

export const markets = [SE, DK, FI, NO];
export const languages = [sv, en, da, fi, no];

export const marketMap = {
  SE: [sv, en],
  DK: [da, en],
  FI: [fi, sv, en],
  NO: [no, en],
};

const humanReadableFileSize: { [key: string]: number } = {
  KB: 1024,
  MB: 1024 * 1024,
  GB: 1024 * 1024,
  TB: 1024 * 1024 * 1024,
};

export const getBytesFromHumanReadableFileSize = function (sizeString: string): number {
  const fileSizeChecker = /(\d+\.?\d*)\s?([a-zA-Z]{2})?$/;

  if (fileSizeChecker.test(sizeString)) {
    const matches = sizeString.match(fileSizeChecker);
    const unit = (matches[2] || '').toLocaleUpperCase();
    const size = parseInt(matches[1]);

    if (unit && humanReadableFileSize[unit]) {
      const baseSize = humanReadableFileSize[unit];

      return size * baseSize;
    }

    return size;
  }

  return 0;
};

export const uuidv4 = () => crypto.randomUUID();

/**
 * @param {HTMLElement} host Use the `this.hostElement` property.
 *
 * @description The `awaitTopbar` function can be used to wait for the topbar to be available.
 *
 * The function will also setup listeners for any market/language changes from the topbar and updates the host element with the `setAttribute` function.
 *
 * Remember to use a Stencil `@Prop` named `market` and/or `language` to take advantage of the auto update feature.
 *
 * @returns The pnTopbar object.
 */
export async function awaitTopbar(host: HTMLElement | any): Promise<PnTopbar> {
  const topbar = () => (window as any).pnTopbar;

  function finishSetup(fallBack?: PnTopbar): PnTopbar {
    const data = fallBack || topbar();

    host.market = data.market;
    host.language = data.language;

    if (data.hasLoaded) {
      topbar().onChangeMarket = (market: string) => (host.market = market);
      topbar().onChangeLanguage = (language: string) => (host.language = language);
    }

    return data;
  }

  return await new Promise(resolve => {
    // If the pntopbar script does not exist. Resolve with default values.
    if (!document.querySelector('pn-topbar'))
      return resolve(finishSetup({ hasLoaded: false, market: host.market || SE, language: host.language || en }));

    if (topbar()?.hasLoaded) return resolve(finishSetup());

    window.addEventListener('topbarLoaded', () => resolve(finishSetup()));
  });
}

/**
 * @description Apply the Ripple effect quick and easy.
 *
 * @example
 * ```css
 * // CSS
 * pn-component-name { `@include pn-ripple($blue700) }
 * ```
 *
 * ```js
 * // tsx
 * onClick((e) => ripple(e, this.hostElement, '.pn-ripple-container'))
 * ```
 * @param event The MouseEvent from a onClick handler.
 * @param host The hostElement of the component (root element).
 * @param rippleClass Append the ripple event to this selector instead of the host element.
 */
export const ripple = (event: MouseEvent | any, host: HTMLElement | any, rippleClass?: string) => {
  const { left, top, width, height } = host.getBoundingClientRect();
  const { clientX = 0, clientY = 0 } = event || {};

  const elSize = width > height ? width : height;

  const element = document.createElement('div');
  element.classList.add('pn-ripple');

  element.style.height = `${elSize * 2}px`;
  element.style.width = `${elSize * 2}px`;
  element.style.left = clientX > 0 ? `${clientX - left}px` : `50%`;
  element.style.top = clientY > 0 ? `${clientY - top}px` : `50%`;

  if (rippleClass) host.querySelector(rippleClass).appendChild(element);
  else host.appendChild(element);

  setTimeout(() => element.remove(), 400);
};
