import { Component, Host, Prop, h, Element, EventEmitter, Event } from '@stencil/core';
import { close } from 'pn-design-assets/pn-assets/icons.js';

@Component({
  tag: 'pn-toast',
  styleUrl: 'pn-toast.scss',
})
export class Pntoast {
  @Element() hostElement: HTMLElement;

  /** Default color is blue, you also have the option of success (green) or warning (red). */
  @Prop() appearance?: '' | 'success' | 'warning';
  /** Set an SVG icon */
  @Prop() icon?: string;
  /** Show close button? */
  @Prop() closable: boolean = false;
  /** Temporarily toast, this will apply a shadow. Use the `hide` property to toggle the visibility. */
  @Prop() temporary: boolean = false;
  /** Control the visibility of the toast. */
  @Prop({ mutable: true }) hide: boolean = false;
  /** Give the toast a HTML ID. */
  @Prop() toastId?: string;

  /** Event fired when close button is pressed and when the temporary toast closes itself */
  @Event() close: EventEmitter<boolean>;

  closeToast() {
    this.hide = true;
    this.close.emit(this.hide);
  }

  render() {
    return (
      <Host>
        <div
          id={this.toastId}
          class="pn-toast"
          role="status"
          data-appearance={this.appearance}
          data-temporary={this.temporary}
          data-hide={this.hide}
        >
          {this.icon && <pn-icon icon={this.icon}></pn-icon>}

          <div class="pn-toast-content">
            <slot />
          </div>

          {this.closable && (
            <button class="pn-toast-button" onClick={() => this.closeToast()} type="button" aria-label="close">
              <pn-icon icon={close} small></pn-icon>
            </button>
          )}
        </div>
      </Host>
    );
  }
}
