import { Component, Host, Prop, h, Element, State, Watch } from '@stencil/core';
import { ripple } from '@/globals/helpers';

/**
 * The `pn-button` is built with a native `button` or `a` element, if you use the `href` attribute.
 *
 * @nativeClick Use the `click` event to listen when the button is clicked.
 */
@Component({
  tag: 'pn-button',
  styleUrl: 'pn-button.scss',
})
export class PnButton {
  buttonEl: HTMLButtonElement | HTMLLinkElement;
  tooltipEl: HTMLDivElement;
  tooltipDx: number;

  openEvents: string[] = ['mouseover', 'focus'];
  closeEvents: string[] = ['mouseleave', 'blur', 'touchcancel', 'touchleave'];
  eventListeners: string[] = [...this.openEvents, ...this.closeEvents];

  @Element() hostElement: HTMLElement;

  @State() tooltipOpen: boolean = false;
  @State() tooltipUpwards: boolean = false;

  /**
   * Select the button appearance: default (blue), `light` (white) and  `warning` (red).
   *
   * @category Visual
   */
  @Prop() appearance?: '' | 'light' | 'warning' = '';
  /**
   * Select the button variant: default (colored background), `outlined` (bordered, no background) and `borderless` (borderless, no background).
   *
   * @category Visual
   */
  @Prop() variant?: '' | 'outlined' | 'borderless' = '';
  /** Use the small button. @category Visual */
  @Prop() small: boolean = false;
  /** Display the loading indicator. @category Visual */
  @Prop({ reflect: true }) loading: boolean = false;

  /**
   * The SVG content of the icon you want.
   * @see {@link https://portal.postnord.com/web-components/?path=/docs/docs-assets--docs pn-design-assets}
   * @category Icon + Tooltip
   */
  @Prop() icon?: string;
  /**
   * When the user focus/hover the button, the `tooltip` text will show up.
   *
   * @summary __You need an `icon` selected for this to work__.
   * @see {@link icon}
   * @category Icon + Tooltip
   */
  @Prop() tooltip?: string;
  /**
   * Place the `icon` to the left of the button.
   *
   * @summary __You need an `icon` selected for this to work__.
   * @see {@link icon}
   * @category Icon + Tooltip
   */
  @Prop() leftIcon: boolean = false;

  /** Select HTML button type. @category Button */
  @Prop() type?: 'button' | 'reset' | 'submit' | '' = 'button';
  /**
   * Connect this button to a HTML form.
   * @category Button
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button#form HTML button form}
   */
  @Prop() form?: string;

  /** Pass an href to make the button into link (a-tag). @category Link */
  @Prop() href?: string;
  /** The rel attribute of the link. @category Link */
  @Prop() rel?: string;
  /** The target attribute of the link. @category Link */
  @Prop() target?: string;
  /** The download attribute of the link. @category Link */
  @Prop() download?: string;

  /** HTML ID. @category HTML attributes */
  @Prop() buttonId?: string = null;
  /** HTML aria-label attribute. @category HTML attributes */
  @Prop() arialabel?: string = null;
  /** HTML aria-labelledby attribute. @category HTML attributes */
  @Prop() arialabelledby?: string = null;
  /** HTML aria-controls attribute. @category HTML attributes */
  @Prop() ariacontrols?: string = null;
  /** HTML aria-pressed attribute. @category HTML attributes */
  @Prop() ariapressed?: string = null;
  /** HTML aria-haspopup attribute. @category HTML attributes */
  @Prop() ariahaspopup?: string = null;
  /** HTML aria-expanded attribute. @category HTML attributes */
  @Prop() ariaexpanded?: string = null;
  /** HTML tabindex. @category HTML attributes */
  @Prop() noTab?: boolean = false;

  @Watch('tooltipOpen')
  openHandler() {
    if (this.tooltipOpen) this.checkTooltipPosition();
  }

  @Watch('tooltip')
  iconOnlyHandler() {
    if (this.tooltip) this.initTooltip();
    else this.removeTooltip();
  }

  componentDidLoad() {
    this.checkTooltipPosition();
    this.iconOnlyHandler();
  }

  componentDidRender() {
    this.buttonEl = this.hostElement.querySelector('.pn-button');

    /** Stencil has a known problem about rendering the `form` attribute.
     * Setting it via setAttribute solves it for now.
     * https://github.com/ionic-team/stencil/issues/2703
     * */
    if (!this.href && this.form) this.buttonEl?.setAttribute('form', this.form);
  }

  getRect(element: HTMLElement): DOMRect {
    return element.getBoundingClientRect();
  }

  initTooltip() {
    this.eventListeners.forEach(e => this.buttonEl.addEventListener(e, this.toggleTooltip));
  }

  removeTooltip() {
    this.eventListeners.forEach(e => this.buttonEl.removeEventListener(e, this.toggleTooltip));
  }

  toggleTooltip = (event: MouseEvent | FocusEvent) => {
    this.tooltipOpen = this.openEvents.includes(event.type);
  };

  setTooltipOffset() {
    this.tooltipEl.style.setProperty('--pn-dx', `${this.tooltipDx / 16}em`);
  }

  checkTooltipPosition() {
    this.tooltipEl = this.hostElement.querySelector('.pn-button-tooltip');
    this.tooltipEl.style.transform = 'translateX(-50%)';
    this.tooltipUpwards = false;

    requestAnimationFrame(() => {
      const { x, y, right, height } = this.getRect(this.tooltipEl);
      const extraMargin = 16;
      this.tooltipDx = 0;
      this.tooltipUpwards = y + height > window.innerHeight;
      this.tooltipEl.style.transform = '';

      if (x < 0) this.tooltipDx = x * -1 + extraMargin;
      if (right > window.innerWidth) this.tooltipDx = window.innerWidth - right - extraMargin - 8;

      requestAnimationFrame(() => this.setTooltipOffset());
    });
  }

  getAttributes() {
    const attrs = this.href
      ? {
          href: this.href,
          rel: !this.rel && this.target === '_blank' ? 'noopener' : this.rel,
          target: this.target ?? null,
          download: this.download ?? null,
        }
      : {
          type: this.type,
          form: this.form,
        };

    const appearances = ['light', 'warning'];
    const variations = ['borderless', 'outlined'];

    return {
      'data-small': this.small,
      'data-loading': this.loading,
      'data-tooltip-open': this.tooltipOpen,
      'data-tooltip-upwards': this.tooltipUpwards,
      'data-icon-only': !!this.tooltip,
      'data-left-icon': this.leftIcon,
      'data-appearance': appearances.includes(this.appearance) ? this.appearance : null,
      'data-variant': variations.includes(this.variant) ? this.variant : null,
      ...attrs,
    };
  }

  getTagName() {
    return this.href ? 'a' : 'button';
  }

  render() {
    const TagName = this.getTagName();

    return (
      <Host>
        <TagName
          class="pn-button"
          id={this.buttonId}
          disabled={this.loading}
          tabindex={this.noTab ? '-1' : null}
          aria-label={this.arialabel}
          aria-labelledby={this.arialabelledby}
          aria-controls={this.ariacontrols}
          aria-pressed={this.ariapressed}
          aria-haspopup={this.ariahaspopup}
          aria-expanded={this.ariaexpanded}
          onClick={e => ripple(e, this.hostElement, '.pn-button-bg')}
          {...this.getAttributes()}
        >
          <div class="pn-button-bg"></div>
          <div class="pn-button-content">
            <div class="pn-button-text" hidden={!!this.tooltip}>
              <slot />
            </div>
            {this.icon && <pn-icon icon={this.icon} />}
          </div>
          <pn-spinner light />

          <div class="pn-button-tooltip" hidden={!this.tooltip}>
            {this.tooltip}
          </div>
        </TagName>
      </Host>
    );
  }
}
