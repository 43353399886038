pn-tile {
  position: relative;
  font-size: 1em;

  /*---------------------------HIDDEN LINK ELEMENT-------------------------------*/
  a.pn-tile-link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    opacity: 0;
  }
  /*---------------------------/HIDDEN LINK ELEMENT-------------------------------*/

  /*---------------------------TILE/CARD STYLES-------------------------------*/
  .pn-tile {
    border: 0.1rem solid $gray200;
    outline: none;
    background: $white;
    box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 0.2);
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    padding: 1.5em;
    padding: min(7%, 1.5em);
    word-break: break-word;
    text-decoration: none;
    border-radius: 0.8rem;
    transition:
      box-shadow 0.1s cubic-bezier(0.6, 0, 0.2, 1) 0.1s,
      background 0.2s linear,
      border 0.1s linear;
    position: relative;
    overflow: hidden;
    text-align: left;
    user-select: text;
    height: 100%;

    &.vertical,
    &.simple {
      text-align: center;

      h3 {
        font-size: 1.5em;
        font-size: clamp(1em, calc(var(--w) * 1.4), 1.5em);
      }

      .tile-slot {
        font-size: 1em;
        font-size: clamp(1.2rem, var(--w), 1em);
      }

      > * {
        margin: 0.5em 0;
      }
    }

    &.simple {
      flex-direction: column;
      align-items: center;

      .tile-slot {
        display: none;
      }

      h3 > pn-icon {
        margin-left: 0.1em;
        vertical-align: bottom;
      }

      > * {
        margin: 0.5em;
      }

      > .text-cont {
        margin: 0.5em;
      }

      > svg,
      > .tile-illustration-slot {
        max-width: clamp(3em, 70%, 10em);
      }

      > svg,
      > .tile-illustration-slot,
      > .text-cont {
        flex: auto;
      }
    }

    &.horizontal {
      flex-wrap: nowrap;
    }

    > * {
      margin: 0.5em;
      z-index: 2;
    }

    > svg,
    > .tile-illustration-slot {
      width: 100%;
      max-width: 8em;
      flex: 0.5 3 calc(calc(32rem - 100%) * 999);
      z-index: 2;
    }

    > .tile-illustration-slot {
      max-width: 13em;

      > * {
        width: 100%;
        display: block;
      }
    }

    > .text-cont {
      flex: 1 1 calc(calc(32rem - 100%) * 999);
      &.horizontal {
        flex: 1 1 0;
        > h3 {
          white-space: nowrap;
        }
      }
    }
    
    h3 {
      color: $blue700;
      font-size: 1.5em;
    }

    .tile-slot {
      color: $gray700;
      margin-top: 0.25em;
    }
  }

  a:focus + .pn-tile {
    // box-shadow: 0 0 0 0.3rem $blue700, 0 0 0 0.6rem $white; This would fit for cards on dark bg, not sure how to incorporate yet
    box-shadow:
      0 0 0 0.3rem $white,
      0 0 0 0.6rem $blue700;
  }

  // Circle transition for vertical cards:hover
  .pn-tile:hover,
  & a:focus + .pn-tile {
    background: $blue25;
    border: 0.1rem solid $blue200;

    &.vertical .tile-circle,
    &.simple .tile-circle {
      transform: translate(-50%, -90%);
      transform: translate(-50%, calc(var(--w) * 2 - 90%)); // This is sex.
      opacity: 1;
    }
  }
  /*---------------------------/TILE/CARD STYLES-------------------------------*/

  /*----------------------MISC (circle, ripple effect)-------------------------*/
  .pn-tile .tile-circle {
    position: absolute;
    top: 0;
    left: 50%;
    width: 40em;
    height: 40em;
    margin: 0;
    background-color: $blue700;
    border-radius: 50%;
    z-index: 1;
    transform: translate(-50%, -100%);
    transition:
      transform 0.5s $easing,
      opacity 0.4s $easing;
    opacity: 0;
  }

  .pn-ripple {
    animation: ripple 0.4s $easing;
    position: absolute;
    border-radius: 50%;
    background: $blue700;
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.05;

    @keyframes ripple {
      to {
        transform: translate(-50%, -50%) scale(1);
        opacity: 0;
      }
    }
  }
  /*--------------------------------/MISC-----------------------------------*/
}
